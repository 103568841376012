import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CreateAppointmentV2RequestContent,
    ServiceId,
    TenantId,
} from '@amzn/ring-rse-scheduling-service-typescript-client';
import { useLanguage } from 'hooks/useLanguage';
import moment from 'moment-timezone';

import { formatPhoneNumberForBackend } from 'utils/phone';
import { useAppSelector } from 'utils/store';
import { findServiceByLocale } from 'utils/tenant';

import { InterestsType } from 'types/state';

/**
 * This hook gather the `appointment` from saved in the store data.
 */
export function useAppointment(): CreateAppointmentV2RequestContent | null {
    const { startTime , endTime } = useAppSelector(state => state.calendar);
    const { lastName , firstName , phone } = useAppSelector(state => state.personDetailsForm);
    const { language } = useLanguage();

    const interests = useAppSelector(state => getTitlesOfSelectedInterests(state.interests));
    const serviceId = useAppSelector(state => {
        const services = state.tenantConfig?.services;
        if (!services) {
            return ServiceId.RsEdefault;
        }
        return findServiceByLocale(services, language)?.serviceId as ServiceId || ServiceId.RsEdefault;
    });

    return  useMemo(() => {
        if (!phone?.value?.number || !phone?.value?.code || !phone || !startTime ||
            !endTime || !firstName?.value || !serviceId) {
            return null;
        }
        return {
            startTime,
            endTime,
            tenantId: TenantId.Intercom,
            serviceId,
            targetPhoneNumber: formatPhoneNumberForBackend({ number: phone.value.number, code: phone.value.code }),
            userData: {
                lastName: lastName.value || undefined, //the user can add '' as lastName
                firstName: firstName.value,
            },
            timezone: moment.tz.guess(),
            interestList: interests,
        };
    }, [startTime, endTime, phone, lastName, firstName, serviceId]);
}

function getTitlesOfSelectedInterests(interests: InterestsType): string[] {
    const { t } = useTranslation();
    return Object.keys(interests)
        .filter(key => interests[key])
        .map(key => t(`interests_${key.toLowerCase()}_title`));
}
