import { useTranslation } from 'react-i18next';

import { LanguageCode } from 'types/language';

export function useLanguage() {
    const { i18n } = useTranslation();
    return {
        language: i18n.language as LanguageCode,
        changeLanguage: i18n.changeLanguage as (language: LanguageCode) => void,
    };
}
