import { Action, createSlice, PayloadAction, SliceCaseReducers, ThunkAction } from '@reduxjs/toolkit';

import { HttpStatusCode } from 'constants/httpCodes';

import { Timeout } from 'types/common';
import { Field, SMSVerificationFormNotification, SMSVerificationFormStatus, SMSVerificationFormType, StateType } from 'types/state';

import { getCodeNotificationMessage, getPhoneNotificationMessage } from './utils/message';
import { getPhoneErrorStatus } from './utils/status';

export const WAITING_PERIOD = 60; // 1 minute

export const initialState: SMSVerificationFormType = {
    code: {
        value: undefined,
        status: 'default',
        message: undefined,
    },
    phone: undefined,
    notification: undefined,
    intervalId: undefined,
    counter: WAITING_PERIOD,
    status: SMSVerificationFormStatus.DEFAULT,
    isRequestSending: false,
};

export const counterSlice = createSlice<SMSVerificationFormType, SliceCaseReducers<SMSVerificationFormType>, string>({
    name: 'smsVerificationForm',
    initialState,
    reducers: {
        changeSMSVerificationPhone: (state, action: PayloadAction<string>) => {
            const isPhoneWasChanged = action.payload !== state.phone;
            if (isPhoneWasChanged) {
                return {
                    ...state,
                    phone: action.payload,
                };
            }
            return state;
        },
        changeSMSVerificationNotification: (state, action: PayloadAction<SMSVerificationFormNotification>) => {
            return {
                ...state,
                notification: action.payload,
            };
        },
        changeSMSVerificationCode: (state, action: PayloadAction<Field<string>>) => {
            return {
                ...state,
                code: action.payload,
            };
        },
        changeSMSVerificationStatus: (state, action: PayloadAction<SMSVerificationFormStatus>) => {
            if ([
                SMSVerificationFormStatus.CODE_ERROR,
                SMSVerificationFormStatus.PENDING,
                SMSVerificationFormStatus.DEFAULT,
            ].includes(action.payload)) {
                return {
                    ...state,
                    status: action.payload,
                };
            }
            /*
                In cases below we should switch off timer because:
                PHONE_400, PHONE_429, PHONE_ERROR - the code was not sent
                SUCCESS - the code has already been verified
             */
            if (state.intervalId) {
                clearInterval(state.intervalId);
            }
            return {
                ...state,
                status: action.payload,
                intervalId: undefined,
                counter: WAITING_PERIOD,
            };
        },
        updateSMSVerificationTimer: (state) => {
            if (state.counter - 1 === 0) {
                if (state.intervalId) {
                    clearInterval(state.intervalId);
                }
                return {
                    ...state,
                    counter: WAITING_PERIOD,
                    intervalId: undefined,
                };
            }
            return {
                ...state,
                counter: state.counter - 1,
            };
        },
        changeSMSVerificationIntervalId: (state, action: PayloadAction<Timeout | undefined>) => {
            if (state.intervalId) {
                clearInterval(state.intervalId);
            }
            return {
                ...state,
                counter: WAITING_PERIOD,
                intervalId: action.payload,
            };
        },
        changeSMSVerificationRequestIsSending: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isRequestSending: action.payload,
            };
        },
        resetSMSVerificationForm: () => {
            return initialState;
        },
    },
});

/**
 * This is a thunk function
 * It is responsible for process the code sending to the user phone number
 * 1) It resets all existing errors
 * 2) It revokes the onSubmit method
 * 3) If the onSubmit call returns error status, then it adds errors
 * 4) If the onSubmit call returns success status, then it starts the timer
 * @param onSubmit
 */
export function sendCodeThunk(onSubmit: () => Promise<HttpStatusCode>): ThunkAction<void, StateType, void, Action> {
    return async (dispatch) => {
        dispatch(changeSMSVerificationRequestIsSending(true));
        dispatch(changeSMSVerificationNotification(undefined));
        dispatch(changeSMSVerificationCode({ value: '', status: 'default', message: undefined }));
        try {
            const statusCode = await onSubmit();
            if (statusCode === HttpStatusCode.OK) {
                dispatch(changeSMSVerificationStatus(SMSVerificationFormStatus.PENDING));
                dispatch(changeSMSVerificationIntervalId(
                    setInterval(() => dispatch(updateSMSVerificationTimer(undefined)), 1000),
                ));
                return;
            }
            dispatch(changeSMSVerificationNotification(getPhoneNotificationMessage(statusCode)));
            dispatch(changeSMSVerificationStatus(getPhoneErrorStatus(statusCode)));
        } catch (e) {
            dispatch(changeSMSVerificationNotification(getPhoneNotificationMessage()));
            dispatch(changeSMSVerificationStatus(getPhoneErrorStatus()));
        } finally {
            dispatch(changeSMSVerificationRequestIsSending(false));
        }
    };
}

/**
 * This is a thunk function
 * It is responsible for process the code verification
 * 1) It revokes the onSubmit method
 * 2) If the onSubmit call returns error status, then it adds errors
 * 3) If the onSubmit call returns success status, then it adds success status and removes an error message of the code data
 * @param sendSMS
 */
export function verifyCodeThunk(sendSMS: () => Promise<HttpStatusCode>): ThunkAction<void, StateType, void, Action> {
    return async (dispatch, getState) => {
        const value = getState().smsVerificationForm.code.value;
        dispatch(changeSMSVerificationRequestIsSending(true));
        try {
            const statusCode = await sendSMS();
            if (statusCode === HttpStatusCode.OK) {
                dispatch(changeSMSVerificationStatus(SMSVerificationFormStatus.SUCCESS));
                dispatch(changeSMSVerificationCode({
                    value,
                    status: 'success',
                    message: 'sms_verification_form_input_success_label',
                }));
                return;
            }
            dispatch(changeSMSVerificationStatus(SMSVerificationFormStatus.CODE_ERROR));
            dispatch(changeSMSVerificationCode({
                value,
                status: 'error',
                message: 'sms_verification_form_input_error_label',
            }));
            if (![HttpStatusCode.BAD_REQUEST].includes(statusCode)) {
                dispatch(changeSMSVerificationNotification(getCodeNotificationMessage(statusCode)));
            }
        } catch (e) {
            dispatch(changeSMSVerificationStatus(SMSVerificationFormStatus.CODE_ERROR));
            dispatch(changeSMSVerificationNotification(getCodeNotificationMessage()));
            dispatch(changeSMSVerificationCode({
                value,
                status: 'error',
                message: 'sms_verification_form_input_error_label',
            }));
        } finally {
            dispatch(changeSMSVerificationRequestIsSending(false));
        }
    };
}

export const {
    changeSMSVerificationPhone,
    changeSMSVerificationNotification,
    changeSMSVerificationCode,
    changeSMSVerificationStatus,
    changeSMSVerificationRequestIsSending,
    changeSMSVerificationIntervalId,
    updateSMSVerificationTimer,
    resetSMSVerificationForm,
} = counterSlice.actions;
export const smsVerificationFormReducer = counterSlice.reducer;
