import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ModalFooter, ModalHeader, useNotificationQueue } from '@amzn/ring-safe-web';
import { cancelAppointment } from 'api/appointment';
import { useRoute } from 'hooks/useRoute';
import { ReactComponent as Question } from 'static/icons/question.svg';

import { ModalPortal } from 'components/ModalPortal';
import { DEFAULT_RETRY_COUNT } from 'constants/common';
import { HttpStatusCode } from 'constants/httpCodes';
import { asyncRetry } from 'utils/common';
import { getRootContainer } from 'utils/layout';

import { NotificationEnum } from 'types/notification';
import { ROUTE } from 'types/route';

type Props = {
    isOpen: boolean;
    id: string;
    onCancel: () => void;
    appointmentId: string;
}

export const CancelAppointmentModal: FC<Props & HTMLAttributes<HTMLElement>> = ({
    isOpen,
    id,
    onCancel,
    appointmentId,
    ...rest
}) => {
    const { t } = useTranslation();
    const notifications = useNotificationQueue();
    const { changeRoute } = useRoute();

    const onCancelAppointment = async () => {
        const response = await asyncRetry(cancelAppointment, DEFAULT_RETRY_COUNT)(appointmentId);
        if (response.status === HttpStatusCode.OK) {
            notifications.add({
                isClosed: false,
                type: 'default',
                message: t(NotificationEnum.AppointmentSuccessCancellation),
            }, NotificationEnum.AppointmentSuccessCancellation);
            changeRoute(ROUTE.WELCOME);
        } else {
            notifications.add({
                isClosed: false,
                type: 'error',
                message: t(NotificationEnum.AppointmentFailedCancellation),
            }, NotificationEnum.AppointmentFailedCancellation);
        }
        onCancel();
    };

    return (
        <ModalPortal
            id={id}
            isOpen={isOpen}
            title={t('cancel_appointment_modal_title')}
            onClose={onCancel}
            icon={<Question aria-hidden />}
            container={getRootContainer()}
            {...rest}
        >
            <ModalHeader>{t('cancel_appointment_modal_header')}</ModalHeader>
            <ModalFooter>
                <Button
                    color='primary'
                    size='m'
                    variant='outline'
                    onClick={onCancel}
                    id={`${id}-cancel-button`}
                    disabled={!isOpen}
                >
                    {t('cancel_appointment_modal_no')}
                </Button>
                <Button
                    color='primary'
                    size='m'
                    variant='filled'
                    onClick={onCancelAppointment}
                    id={`${id}-submit-button`}
                    disabled={!isOpen}
                >
                    {t('cancel_appointment_modal_yes')}
                </Button>
            </ModalFooter>
        </ModalPortal>
    );
};
