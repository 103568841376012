import { CountryCode } from '@amzn/ring-safe-web/types/country';

import { CalendarType } from 'types/calendar';
import { LanguageCode } from 'types/language';
import { Tenant } from 'types/url';

export const DEFAULT_RETRY_COUNT = 1;

export const SUPPORTED_CALENDAR_TYPES = [
    CalendarType.GOOGLE,
    CalendarType.OUTLOOK,
    CalendarType.OFFICE_365,
    CalendarType.YAHOO,
    CalendarType.APPLE,
    CalendarType.ICAL,
];

export const SUPPORTED_COUNTRIES: CountryCode[] = [
    'GB', // Great Britain
    'IE', // Ireland
    'DE', // Germany
    'AT', // Austria
    'FR', // France
    'ES', // Spain
    'IT', // Italy
    'NL', // Netherlands
];


export const DEFAULT_LANGUAGE: LanguageCode = 'en-GB';
export const DEFAULT_TENANT: Tenant = Tenant.INTERCOM;

// must be equivalent to the languages from src/i18n/*
export const SUPPORTED_LANGUAGES: LanguageCode[] = [DEFAULT_LANGUAGE, 'de-DE', 'it-IT','fr-FR', 'es-ES'];
