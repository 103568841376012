import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@amzn/ring-safe-web';
import { useRoute } from 'hooks/useRoute';
import { ReactComponent as Error } from 'static/images/error.svg';

import { block } from 'utils/block';
import { getCookie } from 'utils/cookies';

import { Cookies } from 'types/cookies';
import { ROUTE } from 'types/route';

import './ErrorPage.scss';

const b = block('error-page');

export const ErrorPage: FC<HTMLAttributes<HTMLDivElement>> = ({
    className = '',
    ...rest
}) => {
    const { t } = useTranslation();
    const { changeRoute } = useRoute();

    const hasAppointment = getCookie(Cookies.hasAppointment);

    const onLeftButtonClick = () => {
        window.location.href = 'https://ring.com/collections/all-products';
    };

    const onRightButtonClick = () => {
        changeRoute(hasAppointment ? ROUTE.APPOINTMENT_CONFIRMATION : ROUTE.WELCOME);
    };
    return (
        <div className={b().mix(className)} {...rest}>
            <Error
                className={b('illustration')}
                aria-hidden
            />
            <h1 className={b('title')}>{t('pages_error_title')}</h1>
            <h2 className={b('subtitle')}>{t('pages_error_subtitle')}</h2>
            <div className={b('buttons')}>
                <Button
                    color='primary'
                    size='m'
                    variant='outline'
                    onClick={onLeftButtonClick}
                    id="pages-error-button-shop"
                    className={b('button')}
                >
                    {t('pages_error_button_shop')}
                </Button>
                <Button
                    color='primary'
                    size='m'
                    variant='outline'
                    onClick={onRightButtonClick}
                    id="pages-error-button-appointments-call"
                    className={b('button')}
                >
                    {hasAppointment ? t('pages_error_button_appointments') : t('pages_error_button_new_call')}
                </Button>
            </div>
        </div>
    );
};
