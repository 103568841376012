import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { useNotificationQueue } from '@amzn/ring-safe-web';
import { useLanguage } from 'hooks/useLanguage';

import { getCalendarSourceThunk } from 'components/Calendar/CalendarSlice';
import { useAppDispatch, useAppSelector } from 'utils/store';

export function useLoadCalendar() {
    const { t } = useTranslation();
    const notification = useNotificationQueue();
    const dispatch = useAppDispatch();
    const source = useAppSelector(state => state.calendar.source);
    const { language } = useLanguage();

    const services = useAppSelector(state => state.tenantConfig?.services, shallowEqual);

    useEffect(() => {
        if (!services) {
            return;
        }

        dispatch(getCalendarSourceThunk(language)).catch(() => {
            notification.add({
                type: 'error',
                message: t('calendar_general_error'),
                isClosed: true,
            }, 'calendar_general_error');
        });
    }, [source, services, language]);
}
