import { FC, HTMLAttributes } from 'react';

import { Title } from 'components/Title';
import { block } from 'utils/block';

const b = block('page');

type Props = {
    title: string;
    subtitle?: string;
}

export const PageHeader: FC<Props & HTMLAttributes<HTMLElement>> = ({
    title,
    subtitle,
    className = '',
    ...rest
}) => {
    return (
        <Title
            title={title}
            subtitle={subtitle}
            className={b('title').mix(className)}
            {...rest}
        />
    );
};
