import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Link } from '@amzn/ring-safe-web';
import { useLoadCalendar } from 'hooks/useLoadCalendar';
import { useRoute } from 'hooks/useRoute';
import { ReactComponent as People } from 'static/images/people.svg';

import { useIsNoSlotsNotification } from 'components/Calendar';
import { FooterPortal } from 'components/Footer';
import { PageBody, PageHeader, PageIllustration } from 'components/Page';
import {
    PersonDetailsForm,
    usePersonalDetailsFormCorrect,
    usePersonalDetailsFormHasMessage,
    validatePersonDetailsForm,
} from 'components/PersonDetailsForm';
import { block } from 'utils/block';
import { getRootContainer } from 'utils/layout';
import { useAppDispatch } from 'utils/store';

import { ROUTE } from 'types/route';

import './ContactPage.scss';

const b  = block('contact-page');

export const ContactPage = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { changeRoute } = useRoute();
    const formRef = useRef<HTMLFormElement>(null);
    useLoadCalendar();
    useIsNoSlotsNotification();

    const isDisabled = usePersonalDetailsFormHasMessage();

    const isFormCorrect = usePersonalDetailsFormCorrect();

    const onSubmit = () => {
        dispatch(validatePersonDetailsForm(undefined));
        if (isFormCorrect) {
            changeRoute(ROUTE.ACCOUNT_VERIFICATION);
        }
    };

    const onButtonClick = () => {
        //onButtonClick should trigger form submission to call onSubmit method of PersonDetailsForm
        //But onSubmit method only fires when it is activated by a user - and does not fire when activated by code.
        //https://stackoverflow.com/questions/645555/should-jquerys-form-submit-not-trigger-onsubmit-within-the-form-tag
        formRef.current?.querySelector<HTMLButtonElement>('button[type="submit"]')?.click();
    };

    return (
        <PageBody className={b()}>
            <PageHeader
                title={t('pages_contact_title')}
                subtitle={t('pages_contact_subtitle')}
            />
            <PersonDetailsForm
                ref={formRef}
                className={b('form')}
                onSubmit={onSubmit}
                id="person-details-form"
            />
            <PageIllustration className={b('image')}>
                <People aria-hidden />
            </PageIllustration>
            <FooterPortal container={getRootContainer()}>
                <p className={b('agreement')}>
                    {t('terms_of_service')}
                    <Link
                        type='external'
                        link='https://ring.com/terms'
                        color='neutral' size='s'
                        className={b('agreement-link')}
                        id='contact-page-terms-of-service-link'
                    >
                        {t('terms_of_service_link')}
                    </Link>
                </p>
                <Button
                    color='primary'
                    size='m'
                    variant='filled'
                    onClick={onButtonClick}
                    id="contact-page-redirect-button"
                    disabled={isDisabled}
                >
                    {t('pages_contact_button')}
                </Button>
            </FooterPortal>
        </PageBody>
    );
};
