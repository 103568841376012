import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotificationQueue } from '@amzn/ring-safe-web';

import { useAppSelector } from 'utils/store';

export function useIsNoSlotsNotification() {
    const isNoSlots = useAppSelector(state => state.calendar.isNoSlots);
    const notification = useNotificationQueue();
    const { t } = useTranslation();

    useEffect(() => {
        if (isNoSlots) {
            notification.add({
                type: 'error',
                message: t('calendar_no_slots'),
                isClosed: true,
            }, 'calendar_no_slots');
        }
    }, [isNoSlots]);
}
