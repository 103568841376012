import { useCallback } from 'react';

import { rewrite, track } from 'utils/heap';

/**
 * This hook provides a safe way to use the heap API,
 * calling the heap API functions only if
 * 1. the heap global var has been defined in the index.html head
 * 2. heap was loaded
 */
export const useHeap = () => {
    const addAuthEventProperties = useCallback((authenticated: boolean) => {
        if (window.heap) {
            window.heap.addEventProperties({
                Authenticated: authenticated,
            });
        }
    }, []);

    const load = useCallback(() => {
        if (window.heap && window.heap.loaded) {
            return;
        }

        if (window.heap) {
            window.heap.load('1713893871', {
                rewrite,
            });
        }
    }, []);

    return {
        load,
        track,
        //@TODO connect with Consent Manager
        addAuthEventProperties,
    };
};
