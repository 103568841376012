import { FC, HTMLAttributes, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Card, CardFooter, CardHeader } from '@amzn/ring-safe-web';
import { useLanguage } from 'hooks/useLanguage';
import { ReactComponent as PhoneOn } from 'static/icons/phone-on.svg';
import { ReactComponent as SecurityCheck } from 'static/icons/security-check.svg';

import { block } from 'utils/block';
import { getShortDateString } from 'utils/time';

import './AppointmentCard.scss';

const b = block('appointment-card');

type Props = {
    startTime?: number;
    onCancelClick: () => void;
    onRescheduleClick: () => void;
    id: string;
}

export const AppointmentCard: FC<Props & HTMLAttributes<HTMLElement>> = ({
    className = '',
    startTime,
    onCancelClick,
    onRescheduleClick,
    id,
    ...rest
}) => {
    const { t } = useTranslation();
    const { language } = useLanguage();
    const header = useMemo(() => {
        if (!startTime) {
            return (
                <CardHeader
                    header=' '
                    subHeader=' '
                    className={b('header', { empty: true })}
                />
            );
        }
        const appointmentDate = new Date(startTime);
        const date = getShortDateString(appointmentDate, language);
        return (
            <CardHeader
                className={b('header')}
                header={date}
                subHeader={t('appointment_subheader', { appointmentDate })}
            />
        );
    }, [startTime]);

    return (
        <Card
            className={b({ loading: !startTime }).mix(className)}
            id={id}
            loading={false}
            {...rest}
        >
            {header}
            <p className={b('list-title')}>{t('appointment_list')}</p>
            <ul className={b('list')}>
                <li className={b('li')}>
                    <SecurityCheck
                        className={b('icon')}
                        aria-hidden
                    />
                    <Trans i18nKey="appointment_security">
                        Your call is 100% private - <b className={b('bold')}>data is never shared.</b>
                    </Trans>
                </li>
                <li className={b('li')}>
                    <PhoneOn
                        className={b('icon')}
                        aria-hidden
                    />
                    <Trans i18nKey="appointment_call">
                        Our expert will be <b className={b('bold')}>calling you.</b>
                    </Trans>
                </li>
            </ul>
            <CardFooter>
                <Button
                    color='primary'
                    size='m'
                    variant='link'
                    onClick={onRescheduleClick}
                    className={b('button')}
                    id={`${id}-reschedule`}
                    disabled={startTime === undefined}
                >
                    {t('appointment_reschedule')}
                </Button>
                <Button
                    color='primary'
                    size='m'
                    variant='link'
                    onClick={onCancelClick}
                    className={b('button', { type: 'neutral' })}
                    id={`${id}-cancel`}
                    disabled={startTime === undefined}
                >
                    {t('appointment_cancel')}
                </Button>
            </CardFooter>
        </Card>
    );
};
