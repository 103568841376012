import { objToString } from 'utils/common';

import { CookieType } from 'types/common';
import { Cookies, PrivacyCookie, PrivacyCookieValue } from 'types/cookies';

export function clearCookie(key: string, options: Partial<Omit<CookieType, 'expires' | 'max-age'>>) {
    document.cookie = `${key}=;expires=Thu, 01-Jan-1970 00:00:01 GMT;${objToString(options)};`;
}

export function getCookie(key: string): string | undefined {
    const match = document.cookie.match(`(^|[^;]+)\\s*${key}\\s*=\\s*([^;]+)`);
    if (!match) {
        return undefined;
    }
    return match[2];
}

export function setCookie(key: string, value: string, options: Partial<CookieType>): void {
    document.cookie = `${key}=${value};${objToString(options)}`;
}

export function setHasAppointment(endTime: number) {
    setCookie(Cookies.hasAppointment, 'true', {
        samesite: 'strict',
        secure: true,
        expires: new Date(endTime),
        path: '/',
    });
}

export function clearHasAppointment() {
    clearCookie(Cookies.hasAppointment, { samesite: 'strict', secure: true, path: '/' });
}

export function setIsAuthorized() {
    const expirationDate = new Date();
    // because JWT HttpOnly cookie is stored for 6 months
    expirationDate.setMonth(6 + expirationDate.getMonth());
    setCookie(Cookies.isAuthorized, 'true', {
        samesite: 'strict',
        secure: true,
        expires: expirationDate,
        path: '/',
    });
}

export function clearIsAuthorized() {
    clearCookie(Cookies.isAuthorized, { samesite: 'strict', secure: true, path: '/' });
}

/**
 * This function return whether PrivacyCookie[value] is true
 * PrivacyCookie is setting if the user has accepted the cookie consent
 * @param value
 */
export function getPrivacyCookie(value: PrivacyCookieValue) {
    const privacyCookie = getCookie('cc-privacy-cookie') ?? getCookie('privacy-cookie');
    if (!privacyCookie) {
        return null;
    }
    const settings = JSON.parse(decodeURIComponent(privacyCookie)) as PrivacyCookie;
    return settings[value].toString() === 'true';
}
