import { useEffect } from 'react';
import { useView } from 'hooks/useView';

import { View } from 'types/common';

const MIN_DELAY = 100;
/**
 * This hook intercepts the scroll event on the IOS mobile device and blurs the currently focused element
 */
export function useMobileScroll() {
    const view = useView();
    useEffect(() => {
        let lastEventTimeStamp = 0;
        const listener = (event: Event) => {
            const focusedElement = document.activeElement;
            const delay = event.timeStamp - lastEventTimeStamp;
            if (focusedElement instanceof HTMLElement && view === View.mobile &&  delay < MIN_DELAY) {
                focusedElement.blur();
            }
            lastEventTimeStamp = event.timeStamp;
        };
        window.addEventListener('scroll', listener);
        return () => window.removeEventListener('scroll', listener);
    }, []);
}
