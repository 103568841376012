import { useTranslation } from 'react-i18next';
import { useNotificationQueue } from '@amzn/ring-safe-web';
import { createAppointment } from 'api/appointment';

import { DEFAULT_RETRY_COUNT } from 'constants/common';
import { HttpStatusCode } from 'constants/httpCodes';
import { asyncRetry } from 'utils/common';

import { NotificationEnum } from 'types/notification';
import { ROUTE } from 'types/route';

import { useAppointment } from './useAppointment';
import { useRoute } from './useRoute';


/**
 * UnauthorizedException (401): JWT token is missing, invalid, or unauthorized.
 * BadRequestException (400): the request’s input is invalid.
 * ThrottledException (429): this request is throttled because this client made too many requests.
 * QuotaExceededException (429): client has exceeded quota limit.
 * @param errorStatus
 */
function getNotification(errorStatus?: HttpStatusCode) {
    switch (errorStatus) {
        case HttpStatusCode.BAD_REQUEST:
            return 'pages_appointment_too_many_appointments';
        case HttpStatusCode.UNAUTHORIZED:
            return 'pages_appointment_create_token_expired';
        case HttpStatusCode.OK:
            return 'pages_appointment_create_success';
        default:
            return 'calendar_general_error';
    }
}

/**
 * This hook returns a shared method for CalendarPage and MobileCalendarPage,
 * Which invoke an appointment creation and add appropriate notifications
 */
export function useCreateAppointment(): () => Promise<void> {
    const { t } = useTranslation();
    const notification = useNotificationQueue();
    const appointment = useAppointment()!;
    const { changeRoute } = useRoute();

    return async () => {
        if (appointment) {
            const response = await asyncRetry(createAppointment, DEFAULT_RETRY_COUNT)(appointment);
            notification.removeAll();
            if (response.status === HttpStatusCode.OK) {
                localStorage.setItem('appointmentId', response.data.appointmentId);
                const appointmentId = localStorage.getItem('appointmentId');
                changeRoute(ROUTE.APPOINTMENT_CONFIRMATION, { id: appointmentId });
                //a workaround to show notification on second rendering
                setTimeout(() => {
                    notification.add({
                        type: 'success',
                        message: t(getNotification(response.status)),
                        isClosed: false,
                    }, NotificationEnum.CreateAppointmentNotification);
                }, 1000);
                return;
            }
            notification.add({
                type: 'error',
                message: t(getNotification(response.status)),
                isClosed: true,
            }, NotificationEnum.CreateAppointmentNotification);
        }
    };
}
