import { currentStage, STAGE } from 'constants/stage';

import { LanguageCode } from 'types/language';


export const CM_SCRIPT_TAG_ID = 'consent-script';
export const CM_BANNER_BUTTON_ACCEPT_CLASSNAME = 'consent--accept';
export const CM_BANNER_CLASSNAME = 'consent-banner';
export const CM_BODY_CLASSNAME = 'consent-body';
export const CM_SESSION_STORAGE_TEXT_ID = 'consentStorage';

export class ConsentManager {

    observer: MutationObserver;
    resizeObserver: ResizeObserver;
    onConsentBannerShowListener: typeof this.onConsentBannerShow;
    handleClickAcceptListener: typeof this.handleClickAccept;
    onConsentBannerShow: () => void;
    onConsentBannerRemove: () => void;
    onClickAcceptCallback: (() => void) | undefined;
    scriptId = CM_SCRIPT_TAG_ID;

    readonly observerConfig = { attributes: false, childList: true, subtree: true, characterData: true };

    constructor(
        onConsentBannerShow: () => void,
        onConsentBannerRemove: () => void,
    ) {
        this.observer = new MutationObserver(this.mutationCallback.bind(this));
        this.onConsentBannerShow = onConsentBannerShow;
        this.onConsentBannerShowListener = this.onConsentBannerShow.bind(this);
        this.handleClickAcceptListener = this.handleClickAccept.bind(this);
        this.resizeObserver = new ResizeObserver(this.onConsentBannerShowListener);
        this.onConsentBannerRemove = onConsentBannerRemove;
    }

    /**
     * This function adds a script to the app's <head>, that creates the RingWebsite snippet
     * Documentation: https://confluence.atl.ring.com/pages/viewpage.action?spaceKey=RGWEB&title=Ring+Consent+Management
     */
    addScript(locale: LanguageCode, id = CM_SCRIPT_TAG_ID, callback?: () => void) {
        this.scriptId = id;
        const script = document.createElement('script');
        script.id = id;
        script.type = 'text/javascript';
        script.src = `${STAGE[currentStage].consentManager}?loggedIn=1&locale=${locale}`;
        script.async = true;
        script.onload = callback || null;

        document.head.appendChild(script);
    }

    /**
     * This function returns the consentManager HTML <script> element if it exists
     * @param id
     */
    getScriptElement(id = CM_SCRIPT_TAG_ID) {
        return document.getElementById(id);
    }

    /**
     * This function handles click on "Accept Consent" button
     * @param target
     */
    handleClickAccept({ target }: MouseEvent) {
        if (
            target instanceof HTMLElement &&
            target.className.includes(CM_BANNER_BUTTON_ACCEPT_CLASSNAME)
        ) {
            this.onConsentBannerRemove();
            this.onClickAcceptCallback?.();
            this.onDeleteConsentManager();
        }
    }

    /**
     * This function starts listening the event of clicking the "Accept Consent" button
     * @param callBack
     */
    listenClickAccept(callBack: typeof this.onClickAcceptCallback) {
        this.onClickAcceptCallback = callBack;
        window.addEventListener('click', this.handleClickAcceptListener);
    }

    /**
     * This function listens node resize,
     * Such way, if a node becomes smaller/bigger,
     * the root container and the footer will update their sizes accordingly
     * to avoid overlapping of the page with the ConsentManagerBanner
     */
    listenResize(node: HTMLElement) {
        this.resizeObserver.observe(node);
    }

    /**
     * This function catches the moment, when ConsentManagerBanner appears in DOM
     * and invokes css changing to avoid overlapping of the page with the ConsentManagerBanner
     */
    mutationCallback(mutationList: MutationRecord[]) {
        for (const mutation of mutationList) {
            mutation.addedNodes.forEach((node) => {
                if (node instanceof HTMLElement && node.className === CM_BANNER_CLASSNAME) {
                    this.onConsentBannerShow();
                    this.listenResize(node);
                    this.observer.disconnect();
                }
            }, this);
        }
    }

    /**
     * This function observes DOM mutations inside body element
     * It is needed to intercept the event when a new child (Consent Manage banner) is added to the body
     */
    public listenBannerRender() {
        this.observer.observe(document.body, this.observerConfig);
    }

    /**
     * This function removes all listeners set by the current ConsentManager instance
     */
    public onDeleteConsentManager() {
        /**
         * This is a bug of the consent manager - it places texts to sessionStorage once and then reuse it
         * But if the user change the language - it still returns old one
         * because SESSION_STORAGE_TEXT_ID is the same for all languages
         */
        sessionStorage.removeItem(CM_SESSION_STORAGE_TEXT_ID);
        this.observer.disconnect();
        this.resizeObserver.disconnect();
        window.removeEventListener('click', this.handleClickAccept);
        document.getElementById(this.scriptId)?.remove();
        getConsentBannerElement()?.remove();
        document.getElementsByClassName(CM_BODY_CLASSNAME)[0]?.remove();
    }
}

export function getConsentBannerElement() {
    return document.getElementsByClassName(CM_BANNER_CLASSNAME)[0] as HTMLDivElement | undefined;
}
