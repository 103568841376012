import { useEffect, useState } from 'react';

import { getView } from 'utils/view';

/**
 * This hook returns a current display type
 */
export const useView = () => {
    const [view, setView] = useState(getView(window.innerWidth));

    useEffect(() => {
        const listener = () => setView(getView(window.innerWidth));
        window.addEventListener('resize', listener);
        return () => {
            removeEventListener('resize', listener);
        };
    }, []);

    return view;
};
