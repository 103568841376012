import { Alpha2Code } from 'iso-3166-1-ts';
import { tz } from 'moment-timezone';

export function getUserAlpha2<T extends Alpha2Code>(alpha2Codes: T[]) {
    const timezone = tz.guess(true);
    return alpha2Codes.find(code => tz.zonesForCountry(code).some(countryTimezone => countryTimezone.trim() == timezone.trim()));
}

/**
 * This function returns aplha2 code from the passed language code (defined in RFC 5646).
 * If an aplha2 code is not included in the language code (parsed based on RFC 5646 doc),
 * then it returns undefined
 * @param alpha2Codes
 * @param lang
 */
export function getUserAlpha2FromLang<T extends Alpha2Code>(alpha2Codes: T[], lang: string) {
    return lang
        // a language code is case-insensitive
        .toUpperCase()
        .split('-')
        .slice(1) // the first part is always a language code
        .find(part => alpha2Codes.includes(part as T)) as T;
}
