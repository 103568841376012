import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import { NotificationList, ProgressBar } from '@amzn/ring-safe-web';
import { usePreviousDefined } from 'hooks/usePreviousDefined';
import { useView } from 'hooks/useView';

import { Header } from 'components/Header';
import { Page } from 'components/Page';
import { block } from 'utils/block';
import { getRoute } from 'utils/url';

import { View } from 'types/common';
import { ROUTE, ROUTE_STEPS } from 'types/route';

import './App.scss';

const b = block('app');

export const HEADER = {
    [ROUTE.CONTACT_INFORMATION]: 'pages_contact_header',
    [ROUTE.ACCOUNT_VERIFICATION]: 'pages_account_verification_header',
    [ROUTE.INTERESTS]: 'pages_interests_header',
    [ROUTE.APPOINTMENT_BOOKING]: 'pages_appointment_booking_header',
    [ROUTE.APPOINTMENT_BOOKING_SLOTS]: 'pages_appointment_booking_header',
    [ROUTE.WELCOME]: '',
};

const CURRENT_STEP = {
    [ROUTE.WELCOME]: undefined,
    [ROUTE.CONTACT_INFORMATION]: 1,
    [ROUTE.INTERESTS]: 3, // not available for INTERCOM
    [ROUTE.ACCOUNT_VERIFICATION]: 2,
    [ROUTE.APPOINTMENT_BOOKING]: 3,
    [ROUTE.APPOINTMENT_BOOKING_SLOTS]: 4, // not available on desktop
    [ROUTE.APPOINTMENT_CONFIRMATION]: undefined,
};

const DESKTOP_PAGE_COUNT = CURRENT_STEP[ROUTE.APPOINTMENT_BOOKING]!;
const MOBILE_PAGE_COUNT = CURRENT_STEP[ROUTE.APPOINTMENT_BOOKING_SLOTS]!;

type Props = {
    className: string;
    onAnimationEnd: () => void;
}

export const AppSteps: FC<Props> = ({
    className,
    onAnimationEnd,
}) => {
    const route = getRoute(useLocation().pathname) as ROUTE_STEPS;
    const { t } = useTranslation();
    const view = useView();
    const amountOfSteps = view === View.mobile ? MOBILE_PAGE_COUNT : DESKTOP_PAGE_COUNT;
    const previousAmountOfSteps = usePreviousDefined(CURRENT_STEP[route])!;

    return (
        <Page>
            <NotificationList className={b('notifications')} />
            <Header header={t(HEADER[route])}>
                <ProgressBar amountOfSteps={amountOfSteps + 1} currentStep={CURRENT_STEP[route] || previousAmountOfSteps} />
            </Header>
            <div className={className} onAnimationEnd={onAnimationEnd}>
                <Outlet  />
            </div>
        </Page>
    );
};
