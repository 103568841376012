import { ChangeEvent, FC, FormEvent, HTMLAttributes, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '@amzn/ring-safe-web';
import { ReactComponent as Phone } from 'static/icons/phone.svg';

import { HttpStatusCode } from 'constants/httpCodes';
import { block } from 'utils/block';
import { useAppDispatch, useAppSelector } from 'utils/store';

import { SMSVerificationFormStatus } from 'types/state';

import { getResendButtonLabel } from './utils/message';
import { getInputDisabled, getIsResetButtonDisabled, getIsSubmitButtonDisabled } from './utils/state';
import { SMSVerificationFormNotification } from './SMSVerificationFormNotification';
import {
    changeSMSVerificationCode,
    changeSMSVerificationPhone,
    changeSMSVerificationStatus,
    sendCodeThunk,
    verifyCodeThunk,
} from './SMSVerificationFormSlice';

import './SMSVerificationForm.scss';

const b = block('sms-verification-form');

type Props = {
    phone: string;
    onSubmit: () => Promise<HttpStatusCode>;
    sendSMS: () => Promise<HttpStatusCode>;
    onChangePhoneLinkClick: () => void;
    id: string;
};

/**
 * This component is a form for user authentication by phone number.
 *
 * Once the component is mounted, it will call the passed function `sendSMS`.
 * It should trigger OTP code to a user by SMS.
 * Once OTP code is received by the user and filled in the form input field, the component calls `onSubmit`.
 */
export const SMSVerificationForm: FC<Props & HTMLAttributes<HTMLFormElement>> = ({
    id,
    className = '',
    onChangePhoneLinkClick,
    sendSMS,
    onSubmit,
    phone,
    ...rest
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { status, counter, code, isRequestSending, intervalId } = useAppSelector(({ smsVerificationForm }) => smsVerificationForm);
    const resendButtonLabel = getResendButtonLabel(status, code.status, intervalId);

    useEffect(() => {
        dispatch(changeSMSVerificationPhone(phone));
        dispatch(sendCodeThunk(sendSMS));
    }, [phone, sendSMS]);

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(changeSMSVerificationCode({
            value: e.target.value,
            message: undefined,
            status: 'default',
        }));
        if (code.status !== 'default') {
            dispatch(changeSMSVerificationStatus(SMSVerificationFormStatus.PENDING));
        }
    };

    const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(verifyCodeThunk(onSubmit));
    };

    const onFormReset = () => {
        dispatch(sendCodeThunk(sendSMS));
    };

    return (
        <form
            id={id}
            className={b().mix(className)}
            onSubmit={onFormSubmit}
            onReset={onFormReset}
            tabIndex={0}
            {...rest}
        >
            <SMSVerificationFormNotification id={`${id}-notification`} />
            <h1 className={b('header')}>
                {t('sms_verification_form_title')}
            </h1>
            <div className={b('phone-container')}>
                {t('sms_verification_form_phone_label')}
                <div className={b('phone')} id={`${id}-phone`}>
                    <Phone aria-hidden />
                    <span
                        data-heap-redact-text={true}
                    >
                        {phone}
                    </span>
                </div>
            </div>
            <Button
                variant='link'
                onClick={onChangePhoneLinkClick}
                color='primary'
                size='m'
                type='button'
                id={`${id}-link`}
                className={b('link')}
                tabIndex={rest.tabIndex}
            >
                {t('sms_verification_form_link')}
            </Button>
            <Input
                label={t(code.message || 'sms_verification_form_input_label')}
                status={code.status}
                id={`${id}-code`}
                name='code'
                value={code.value || ''}
                onChange={onInputChange}
                type='number'
                className={b('input')}
                disabled={isRequestSending || getInputDisabled(status)}
                autoComplete='one-time-code'
                placeholder='000000'
            />
            <Button
                color='primary'
                size='m'
                variant='filled'
                id={`${id}-submit-button`}
                type='submit'
                className={b('button')}
                disabled={getIsSubmitButtonDisabled(status, isRequestSending, code.value)}
                tabIndex={rest.tabIndex}
            >
                {t('sms_verification_form_button')}
            </Button>
            <p className={b('resend-label')}>
                {t(resendButtonLabel, { counter })}
            </p>
            <Button
                color='primary'
                size='m'
                variant='link'
                type='reset'
                id={`${id}-reset-button`}
                className={b('resend-button')}
                disabled={getIsResetButtonDisabled(status, isRequestSending, intervalId)}
                tabIndex={rest.tabIndex}
            >
                {t('sms_verification_form_resend_button')}
            </Button>
        </form>
    );
};
