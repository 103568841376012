
const HEADER_CONTAINER_CLASSNAME = 'nav-member nav-dropdown--container';

export class NavHeaderObserver {

    observer: MutationObserver;
    onNavContainerShow: (loginElement: HTMLElement) => void;
    loginElement : HTMLElement | undefined;
    

    readonly observerConfig = { attributes: false, childList: true, subtree: true, characterData: true };

    constructor(
        onNavContainerShow: (loginElement: HTMLElement) => void,
    ) {
        this.observer = new MutationObserver(this.mutationCallback.bind(this));
        this.onNavContainerShow = onNavContainerShow;
    }

    checkAllDescendants(addedNodes: NodeList) {
        addedNodes.forEach((node) => {
            if (
                this.loginElement === undefined &&
                node instanceof HTMLElement &&
                node.className === HEADER_CONTAINER_CLASSNAME
            ) {
                this.loginElement = node;
                this.onNavContainerShow(this.loginElement);
                this.observer.disconnect();
                return;
            }
            this.checkAllDescendants(node.childNodes);
        });
    }

    mutationCallback(mutationList: MutationRecord[]) {
        for (const mutation of mutationList) {
            this.checkAllDescendants(mutation.addedNodes);
        }
    }

    listenNavContainerRender() {
        this.observer.observe(document.body, this.observerConfig);
    }
}
