export enum ROUTE {
    CONTACT_INFORMATION = '/steps/contact-information',
    ACCOUNT_VERIFICATION = '/steps/account-verification',
    INTERESTS = '/steps/interests',
    APPOINTMENT_CONFIRMATION = '/appointment-confirmation',
    APPOINTMENT_BOOKING = '/steps/appointment-booking',
    APPOINTMENT_BOOKING_SLOTS = '/steps/appointment-booking/slots',
    WELCOME = '/',
}

export type ROUTE_STEPS = Extract<
    ROUTE,
    ROUTE.CONTACT_INFORMATION |
    ROUTE.ACCOUNT_VERIFICATION |
    ROUTE.INTERESTS |
    ROUTE.APPOINTMENT_BOOKING |
    ROUTE.APPOINTMENT_BOOKING_SLOTS
>
