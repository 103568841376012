import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from 'hooks/useLanguage';

import { LanguageSelector } from 'components/LanguageSelector';
import { SUPPORTED_LANGUAGES } from 'constants/common';
import { findLanguageInList, replaceLanguageInUrl, updateLangAttribute } from 'utils/language';
import { NavHeaderObserver } from 'utils/navHeaderObserver';
import { getPath } from 'utils/url';
import { isMobile } from 'utils/view';


const LANGUAGE_SELECTOR_ID = 'language-selector';
const LANGUAGE_SELECTOR_ROOT_ID = 'language-selector-root';
const LANGUAGE_SELECTOR_ROOT_CLASSNAME = 'nav-member';
const HEADER_CONTAINER_CLASSNAME = 'nav-member nav-dropdown--container';

export function useInitLanguageSelector() {

    const history = useNavigate();
    const { language, changeLanguage } = useLanguage();

    const onLanguageChanged = (lang: string) => {
        const languageCode = findLanguageInList(lang, SUPPORTED_LANGUAGES);
        if (!languageCode) {
            return;
        }
        const url = window.location.href;
        const newUrl = replaceLanguageInUrl(url, languageCode);
        if (url !== newUrl) {
            history(getPath(newUrl));
            changeLanguage(languageCode);
            updateLangAttribute(languageCode);
        }
    };

    const mountLanguageSelector = (loginElement: HTMLElement) => {
        const languageSelectorContainer = addLanguageSelectorContainer(loginElement);
        const root = createRoot(languageSelectorContainer);
        updateLangAttribute(language);
        root.render(
            <LanguageSelector
                id={LANGUAGE_SELECTOR_ID}
                disabled={false}
                languageCodes={SUPPORTED_LANGUAGES}
                isAbsolute={false}
                checkedLanguage={language}
                closeOnClickOutside={true}
                closeOnFocusOutside={!isMobile()}
                onChangeCallback={onLanguageChanged}
            />,
        );
    };

    useEffect(() => {
        const loginElement = document.getElementsByClassName(HEADER_CONTAINER_CLASSNAME)[0];
        if (loginElement && loginElement instanceof HTMLElement) {
            mountLanguageSelector(loginElement);
            return;
        }
        const navHeaderObserver = new NavHeaderObserver((loginElement) => {
            mountLanguageSelector(loginElement);
        });
        navHeaderObserver.listenNavContainerRender();
    }, []);
}

function addLanguageSelectorContainer(loginElement: HTMLElement) {
    const languageSelectorContainer = document.createElement('div');
    languageSelectorContainer.id = LANGUAGE_SELECTOR_ROOT_ID;
    languageSelectorContainer.className = LANGUAGE_SELECTOR_ROOT_CLASSNAME;
    loginElement.after(languageSelectorContainer);
    return languageSelectorContainer;
}
