import { Stage } from 'types/common';

/**
 * For more information:
 * https://quip-amazon.com/dHisAH3VJOeg/VAS-TIME-setup
 */
export const STAGE = {
    alpha: {
        storeId: '9b54394d-2964-46a0-a468-1377796f61rce5a',
        merchantId: 'A1QM433KPSJK8M',
        calendarSourceId: 'https://vaswebapp-purchase-iad.iad.proxy.amazon.com/localservices/ya/scheduling/widget',
        endpoints: '',
        consentManager: 'https://d1ber8az9zrhox.cloudfront.net/common/js/consent-manager-ead73f8ff59ee12f58e7ac1ba9956476.js',
    },
    beta: {
        storeId: '74a6f8cc-852f-4e23-b028-f303676e9c06',
        merchantId: 'A1QM433KPSJK8M',
        calendarSourceId: 'https://development.amazon.com/localservices/ya/scheduling/widget',
        endpoints: '',
        consentManager: 'https://d1ber8az9zrhox.cloudfront.net/common/js/consent-manager-ead73f8ff59ee12f58e7ac1ba9956476.js',
    },
    gamma: {
        storeId: '9925caaf-219b-4224-b54d-2e4143241b22',
        merchantId: 'A3EJCRQK8I3V3T',
        calendarSourceId: 'https://pre-prod.amazon.com/localservices/ya/scheduling/widget',
        endpoints: '',
        consentManager: 'https://d1ber8az9zrhox.cloudfront.net/common/js/consent-manager-ead73f8ff59ee12f58e7ac1ba9956476.js',
    },
    prod: {
        storeId: '89f1a354-5743-417d-a092-83a51652a933',
        merchantId: 'A3EJCRQK8I3V3T',
        calendarSourceId: '/localservices/ya/scheduling/widget',
        endpoints: '',
        consentManager: 'https://d39xvdj9d5ntm1.cloudfront.net/common/js/consent-manager-ead73f8ff59ee12f58e7ac1ba9956476.js',
    },
    test: {
        storeId: '9925caaf-219b-4224-b54d-2e4143241b22',
        merchantId: 'A3EJCRQK8I3V3T',
        calendarSourceId: 'https://pre-prod.amazon.com/localservices/ya/scheduling/widget',
        endpoints: 'https://4zdpietng2.execute-api.us-east-1.amazonaws.com',
        consentManager: 'https://d1ber8az9zrhox.cloudfront.net/common/js/consent-manager-ead73f8ff59ee12f58e7ac1ba9956476.js',
    },
};

export const currentStage = 'prod' as Stage;
