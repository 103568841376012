import { useTranslation } from 'react-i18next';
import { Button } from '@amzn/ring-safe-web';
import houseIntercom from 'static/images/house-intercom.png';
import intercom from 'static/images/intercom.png';
import mobileIntercom from 'static/images/mobile-intercom.png';

import { Footer } from 'components/Footer';
import { block } from 'utils/block';

const b  = block('welcome-page');

export const WelcomePageSkeleton = () => {
    const { t } = useTranslation();
    return (
        <section className={b({ loading: true })}>
            <h1 className={b('title')}>Welcome! Let’s get your call scheduled.</h1>
            <h2 className={b('subtitle')}>Here’s how it works.</h2>
            <section className={b('services')}>
                <article className={b('service')}>
                    <img aria-hidden src={intercom} />
                    <h1 className={b('header')}/>
                </article>
                <article className={b('service')}>
                    <img aria-hidden src={mobileIntercom} />
                    <h1 className={b('header')}/>
                </article>
                <article className={b('service')}>
                    <img aria-hidden src={houseIntercom} />
                    <h1 className={b('header')}/>
                </article>
                <Footer>
                    <Button
                        color='primary'
                        size='m'
                        variant='filled'
                        id="welcome-page-redirect-button"
                        disabled
                        className={b('button')}
                    >{t('loading')}</Button>
                </Footer>
            </section>
        </section>
    );
};
