import { FC, HTMLAttributes, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { CommonCalendarWidgetImplementor, Listener, Payload } from '@amzn/common-calendar-widget-katal';
import { useNotificationQueue } from '@amzn/ring-safe-web';
import { useLanguage } from 'hooks/useLanguage';

import { block } from 'utils/block';
import { useAppDispatch, useAppSelector } from 'utils/store';
import { getTimestampWithShiftedMinutes } from 'utils/time';

import { useIsNoSlotsNotification } from './CalendarHooks';
import { CalendarSkeleton } from './CalendarSkeleton';
import { getCalendarSourceThunk, setIsNoSlots, setSlotTime } from './CalendarSlice';

import '@amzn/katal-components/styles.css';
import '@amzn/common-calendar-widget-katal/public/css/index.css';
import './Calendar.global.scss';
import './Calendar.scss';

const b = block('calendar');

type Props = {
    id: string;
    countOfSlots: number;
    numOfWeeksToShow: number;
    dateString?: string;
}

const SLOT_SELECT_EVENT = 'slotsel';
const SLOT_DESELECT_EVENT = 'slotdesel';
const DAY_SELECT_EVENT = 'datesel';

export const Calendar: FC<Props & HTMLAttributes<HTMLDivElement>> = memo(({
    id,
    numOfWeeksToShow,
    countOfSlots,
    dateString,
    className = '',
    ...rest
}) => {

    const [isLoadingCalendar, setIsLoadingCalendar] = useState(true);
    const notification = useNotificationQueue();
    const dispatch = useAppDispatch();
    useIsNoSlotsNotification();
    const { t } = useTranslation();
    const { language } = useLanguage();
    const calendarRef = useRef<HTMLDivElement>(null);
    const source = useAppSelector(state => state.calendar.source, shallowEqual);

    useEffect(() => {
        dispatch(getCalendarSourceThunk(language));
    }, [language]);

    useEffect(() => {
        if (calendarRef.current && source) {
            calendarRef.current.innerHTML = source;
            renderCommonCalendarWidget();
        }
    }, [source]);

    const slotSelection: Listener = (event, payload, calendarInstance) => {
        const { startTime, duration } = calendarInstance.getSlotDataById(payload.detail.slotId);
        dispatch(setSlotTime({ startTime, endTime: getTimestampWithShiftedMinutes(startTime, duration) }));
    };

    const slotDeselection: Listener = () => {
        dispatch(setSlotTime({ startTime: undefined, endTime: undefined }));
    };

    const renderCommonCalendarWidget = () => {
        setIsLoadingCalendar(true);
        const calendarInstance = new CommonCalendarWidgetImplementor(id, { numOfWeeksToShow });
        calendarInstance.load(
            () => {
                setIsLoadingCalendar(false);
                notification.remove('calendar_no_slots');
                notification.remove('calendar_general_error');
                calendarInstance.attachEventListeners((event: Event, payload: Payload) => {
                    slotSelection(event, payload, calendarInstance);
                }, `${SLOT_SELECT_EVENT}:${id}`);

                calendarInstance.attachEventListeners((event: Event, payload: Payload) => {
                    slotDeselection(event, payload, calendarInstance);
                }, `${SLOT_DESELECT_EVENT}:${id}`);

                calendarInstance.attachEventListeners((event: Event, payload: Payload) => {
                    slotDeselection(event, payload, calendarInstance);
                }, `${DAY_SELECT_EVENT}:${id}`);
            },
            (slotStatus) => {
                if (slotStatus.isNoSlots) {
                    dispatch(setIsNoSlots(true));
                } else {
                    notification.add({
                        type: 'error',
                        message: t('calendar_general_error'),
                        isClosed: true,
                    }, 'calendar_general_error');
                }
            },
        );
    };

    return (
        <div {...rest} className={b('container').mix(className)}>
            <CalendarSkeleton
                countOfSlots={countOfSlots}
                numOfWeeksToShow={numOfWeeksToShow}
                dateString={dateString}
                isHidden={!isLoadingCalendar}
            />
            <div id={id} ref={calendarRef} className={b({ hidden: isLoadingCalendar }).mix('katal')} />
        </div>
    );
});
