import { HttpStatusCode } from 'constants/httpCodes';

import { SMSVerificationFormStatus } from 'types/state';

export const PHONE_STATUS = {
    400: SMSVerificationFormStatus.PHONE_400,
    429: SMSVerificationFormStatus.PHONE_429,
    ERROR: SMSVerificationFormStatus.PHONE_ERROR,
};

/**
 * This function converts HTTP status (number) into phone SMSVerificationFormStatus
 * @param status - should be error code
 */
export function getPhoneErrorStatus(status?: HttpStatusCode): SMSVerificationFormStatus {
    if (!status) {
        return PHONE_STATUS.ERROR;
    }
    const key = (status in PHONE_STATUS  ? status : 'ERROR') as keyof typeof PHONE_STATUS;
    return PHONE_STATUS[key];
}
