import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { InterestsType } from 'types/state';

export const initialState = {
    VIDEO: false,
    CAMERA : false,
    ALARM_SYSTEM: false,
    HOME: false,
    GENERAL: false,
};

export const interestsSlice = createSlice<InterestsType, SliceCaseReducers<InterestsType>, string>({
    name: 'interests',
    initialState,
    reducers: {
        changeInterests: (state, action: PayloadAction<keyof InterestsType>) => {
            if (action.payload === 'GENERAL') {
                return {
                    VIDEO: false,
                    CAMERA : false,
                    ALARM_SYSTEM: false,
                    HOME: false,
                    GENERAL: !state[action.payload],
                };
            }
            return {
                ...state,
                GENERAL: false,
                [action.payload]: !state[action.payload],
            };
        },
    },
});

export const {
    changeInterests,
} = interestsSlice.actions;
export const interestsReducer = interestsSlice.reducer;
