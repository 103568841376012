import { FC } from 'react';
import { GetAppointmentV2ResponseContent } from '@amzn/ring-rse-scheduling-service-typescript-client';

import { Appointment, AppointmentSkeleton } from 'components/Appointment';
import { block } from 'utils/block';

const b = block('appointment-page');

type Props = {
    currentAppointmentId?: string;
    appointments?: GetAppointmentV2ResponseContent[];
    setCurrentAppointment: (appointment: GetAppointmentV2ResponseContent) => void;
}

export const AppointmentPageList: FC<Props> = ({
    currentAppointmentId,
    appointments,
    setCurrentAppointment,
}) => {

    if (!currentAppointmentId || !appointments) {
        return <AppointmentSkeleton />;
    }

    return (
        <>
            {appointments?.map((appointment) => (
                <Appointment
                    appointmentId={appointment.appointmentId}
                    startTime={appointment.startTime}
                    onClick={() => setCurrentAppointment(appointment)}
                    checked={currentAppointmentId === appointment.appointmentId}
                    className={b('appointment')}
                    key={appointment.appointmentId}
                />
            ))}
        </>
    );
};
