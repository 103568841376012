import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ModalFooter, ModalHeader } from '@amzn/ring-safe-web';
import { useRoute } from 'hooks/useRoute';
import { ReactComponent as Question } from 'static/icons/question.svg';

import { ModalPortal } from 'components/ModalPortal';
import { getRootContainer } from 'utils/layout';

import { ROUTE } from 'types/route';

type Props = {
    isOpen: boolean;
    id: string;
    appointmentId: string;
    onCancel: () => void;
}

export const RescheduleAppointmentModal: FC<Props & HTMLAttributes<HTMLElement>> = ({
    isOpen,
    id,
    onCancel,
    appointmentId,
    ...rest
}) => {
    const { t } = useTranslation();
    const { changeRoute } = useRoute();

    const onRescheduleAppointment = async () => {
        if (!appointmentId) {
            return;
        }
        changeRoute(ROUTE.APPOINTMENT_BOOKING, { id: appointmentId });
    };

    return (
        <ModalPortal
            id={id}
            isOpen={isOpen}
            title={t('reschedule_appointment_modal_title')}
            onClose={onCancel}
            icon={<Question aria-hidden />}
            container={getRootContainer()}
            {...rest}
        >
            <ModalHeader>{t('reschedule_appointment_modal_header')}</ModalHeader>
            <ModalFooter>
                <Button
                    color='primary'
                    size='m'
                    variant='outline'
                    onClick={onCancel}
                    id={`${id}-cancel-button`}
                    disabled={!isOpen}
                >
                    {t('reschedule_appointment_modal_no')}
                </Button>
                <Button
                    color='primary'
                    size='m'
                    variant='filled'
                    onClick={onRescheduleAppointment}
                    id={`${id}-submit-button`}
                    disabled={!isOpen}
                >
                    {t('reschedule_appointment_modal_yes')}
                </Button>
            </ModalFooter>
        </ModalPortal>
    );
};
