/**
 * This function calls the document download event
 * @param filename must contain extension
 * @param blob
 */
export function downloadFile(blob: Blob, filename: string) {
    const link = document.createElement('a');
    link.setAttribute('download', filename);
    link.href = window.URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
}
