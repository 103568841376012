// CAUTION: the keys must match the i18n translation files
// based on https://w.amazon.com/bin/view/AWS/Teams/Localization/Operation_Guide/LanguageNamingConventions/
export const LANGUAGES = {
    'zh-CN': {
        name: 'Chinese (Simplified)',
        nameLocal: '中文',
        nameLocalShort: '中文',
        languageCode: 'ZH',
    },
    'zh-TW': {
        name: 'Chinese (Traditional) (Taiwan)',
        nameLocal: '中文(台灣)',
        nameLocalShort: '中文(台灣)',
        languageCode: 'ZH',
    },
    'en-US': {
        name: 'English (United States)',
        nameLocal: 'English (United States)',
        nameLocalShort: 'English',
        languageCode: 'EN',
    },
    'fr-CA': {
        name: 'French (Canada)',
        nameLocal: 'Français (Canada)',
        nameLocalShort: 'Français',
        languageCode: 'FR',
    },
    'fr-FR': {
        name: 'French (France)',
        nameLocal: 'Français (France)',
        nameLocalShort: 'Français',
        languageCode: 'FR',
    },
    'de-DE': {
        name: 'German (Germany)',
        nameLocal: 'Deutsch (Deutschland)',
        nameLocalShort: 'Deutsch',
        languageCode: 'DE',
    },
    'id-ID': {
        name: 'Indonesian (Bahasa)',
        nameLocal: 'Bahasa Indonesia',
        nameLocalShort: 'Bahasa',
        languageCode: 'ID',
    },
    'it-IT': {
        name: 'Italian (Italy)',
        nameLocal: 'Italiano (Italia)',
        nameLocalShort: 'Italian',
        languageCode: 'IT',
    },
    'ja-JP': {
        name: 'Japanese (Japan)',
        nameLocal: '日本語 (日本)',
        nameLocalShort: '日本語 ',
        languageCode: 'JA',
    },
    'ko-KR': {
        name: 'Korean (Korea)',
        nameLocal: '한국어 (한국)',
        nameLocalShort: '한국어',
        languageCode: 'KO',
    },
    'pt-BR': {
        name: 'Portuguese (Brazil)',
        nameLocal: 'Português (Brasil)',
        nameLocalShort: 'Português',
        languageCode: 'PT',
    },
    'pt-PT': {
        name: 'Portuguese (Portugal)',
        nameLocal: 'Português (Portugal)',
        nameLocalShort: 'Português',
        languageCode: 'PT',
    },
    'ru-RU': {
        name: 'Russian (Russia Federation)',
        nameLocal: 'Русский (Российская Федерация)',
        nameLocalShort: 'Русский',
        languageCode: 'RU',
    },
    'es-XN': {
        name: 'Spanish Global (including Colombian and Mexican flavors)',
        nameLocal: 'Español',
        nameLocalShort: 'Español',
        languageCode: 'ES',
    },
    'es-XL': {
        name: 'Spanish (Latin America/LATAM)',
        nameLocal: 'Español (América Latina/LATAM)',
        nameLocalShort: 'Español',
        languageCode: 'ES',
    },
    'es-ES': {
        name: 'Spanish (Spain/EMEA)',
        nameLocal: 'Español (España/EMEA)',
        nameLocalShort: 'Español',
        languageCode: 'ES',
    },
    'th-TH': {
        name: 'Thai (Thailand)',
        nameLocal: 'ไทย (ประเทศไทย)',
        nameLocalShort: 'ไทย',
        languageCode: 'TH',
    },
    'tr-TR': {
        name: 'Turkish (Turkey)',
        nameLocal: 'Türkçe (Türkiye)',
        nameLocalShort: 'Türkçe',
        languageCode: 'TR',
    },
    'vi-VN': {
        name: 'Vietnamese (Vietnam)',
        nameLocal: 'Tiếng Việt (Việt Nam)',
        nameLocalShort: 'Tiếng Việt',
        languageCode: 'VI',
    },
    'da-DK': {
        name: 'Danish (Denmark)',
        nameLocal: 'Dansk (Danmark)',
        nameLocalShort: 'Dansk',
        languageCode: 'DA',
    },
    'en-GB': {
        name: 'English (Great Britain)',
        nameLocal: 'English (Great Britain)',
        nameLocalShort: 'English',
        languageCode: 'EN',
    },
    'fi-FI': {
        name: 'Finnish (Finland)',
        nameLocal: 'Suomi (Suomi)',
        nameLocalShort: 'Suomi',
        languageCode: 'FI',
    },
    'he-IL': {
        name: 'Hebrew (Israel)',
        nameLocal: 'עברית (ישראל)',
        nameLocalShort: 'עברית',
        languageCode: 'HE',
    },
    'nl-NL': {
        name: 'Dutch (Netherlands)',
        nameLocal: 'Nederlands (Nederland)',
        nameLocalShort: 'Nederlands',
        languageCode: 'NL',
    },
    'nb-NO': {
        name: 'Norwegian (Bokmål)',
        nameLocal: 'Norsk (Bokmål)',
        nameLocalShort: 'Norsk (Bokmål)',
        languageCode: 'NB',
    },
    'no-NO': {
        name: 'Norwegian (Norway)',
        nameLocal: 'Norsk (Noreg)',
        nameLocalShort: 'Norsk',
        languageCode: 'NO',
    },
    'sv-SE': {
        name: 'Swedish (Sweden)',
        nameLocal: 'Svenska (Sverige)',
        nameLocalShort: 'Svenska',
        languageCode: 'SV',
    },
} as const;
