import { createContext, FC, PropsWithChildren, useState } from 'react';
import { Location } from 'react-router-dom';

type  DisplayLocationContextType = {
    displayLocation: Location | null;
    setDisplayLocation: (location: Location | null) => void;
}
export const DisplayLocationContext = createContext<DisplayLocationContextType>({
    displayLocation: null,
    setDisplayLocation: () => {},
});

export const DisplayLocation: FC<PropsWithChildren> = ({ children }) => {
    const [displayLocation, setDisplayLocation] = useState<Location | null>(null);
    return (
        <DisplayLocationContext.Provider value={{ displayLocation, setDisplayLocation }}>
            {children}
        </DisplayLocationContext.Provider>
    );
};
