import { Alpha2Code } from 'iso-3166-1-ts';

import { DEFAULT_LANGUAGE } from 'constants/common';
import { LANGUAGES } from 'constants/languages';
import { getUserAlpha2, getUserAlpha2FromLang } from 'utils/country';
import { getTenant } from 'utils/url';

import { LanguageCode } from 'types/language';

/**
 * This function substitutes the existing language with the language provided as an argument.
 * If the url does not include any language, the function appends the provided language to the url.
 * @param url
 * @param language
 */
export function replaceLanguageInUrl(url: string, language: LanguageCode) {
    const { pathname, hash, search , origin } = new URL(url);
    const currentLanguage = getLanguageFromPath(pathname)?.toLowerCase();
    if (!currentLanguage) {
        return addLanguageToUrl(url, language);
    }
    const newPathname = pathname.split('/').map(path => path === currentLanguage ? language : path).join('/');
    return `${origin}${newPathname}${search}${hash}`.toLowerCase();
}

/**
 * This function adds the passed language to the url:
 * It uses a fact that the language is always follows a tenant if it exists
 * And a tenant is always the first part of a pathname
 *
 * CAUTION
 * The url must not already contain any language
 *
 * @param url
 * @param language
 */
export function addLanguageToUrl(url: string, language: LanguageCode) {
    const { pathname, hash, search , origin } = new URL(url);
    const tenant = getTenant(pathname);
    const pathArr = pathname.split('/');
    const pathRest = tenant ? pathArr.slice(2).join('/') : pathArr.slice(1).join('/');
    const pathStart = tenant ? `/${tenant}/${language}` : `/${language}`;
    return `${origin}${pathStart}/${pathRest || ''}${search}${hash}`.toLowerCase();
}

/**
 * This function returns a language code (RFC 5646) in lowercase or null
 * It uses a fact that the language is always follows a tenant if it exists
 * And a tenant is always the first part of a pathname
 * @param pathname
 * @param languageCodes
 */
export function getLanguageFromPath(pathname: string, languageCodes: LanguageCode[] = Object.keys(LANGUAGES)): LanguageCode | null {
    const tenant = getTenant(pathname);
    const paths = pathname.split('/');
    const languagePath = tenant ? paths[2] : paths[1];
    if (!languagePath) {
        return null;
    }
    return findLanguageInList(languagePath, languageCodes);
}

/**
 * This function determines whether the string passed in is a language,
 * If it is, it returns the corresponding language,
 * Otherwise it returns null
 * @param language
 * @param languages
 */
export function findLanguageInList<T extends LanguageCode>(language: string, languages: T[]) {
    return languages.find(languageCode => languageCode.toLowerCase() === language.toLowerCase()) || null;
}

/**
 * This function returns language code from the passed language (defined in RFC 5646).
 * @param languageTag
 */
export function getLanguageFromTag(languageTag: string) {
    return languageTag
        // a language code is case-insensitive
        .toLowerCase()
        .split('-')[0]; // the first part is always a language code
}

/**
 * This function creates a key in [language]-[alpha2] format.
 * @param language
 * @param alpha2
 */
export function constructLanguageCode<T extends LanguageCode>(language: string, alpha2: Alpha2Code ) {
    return `${language.toLowerCase()}-${alpha2}` as T;
}

/**
 * This function looks for a LanguageCode that matches with the passed language and alphaCode
 * @param languages
 * @param language
 * @param alphaCode
 */
export function findLanguage<T extends LanguageCode>(languages: T[], language?: string, alphaCode?: Alpha2Code) {
    if (!alphaCode || !language) {
        return null;
    }
    const languageKey = constructLanguageCode(language, alphaCode);
    return languageKey ? findLanguageInList(languageKey, languages) : null;
}

/**
 * This function takes a language and timezone from the user's browser and
 * tries to find a language from the passed languages (in format [language]-[alpha2])
 * that fits user's guessed country (alpha2) and language
 * If it can't find appropriate LanguageCode, then returns null
 * @param languages
 */
export function guessUserLanguage<T extends LanguageCode>(languages: T[]) {
    const alphaCodes = languages.map(languageTag => languageTag.split('-')[1]) as Alpha2Code[];
    const browserLanguage = window.navigator.language;
    const language = getLanguageFromTag(browserLanguage);
    if (!language) {
        return null;
    }
    const languageKey =
        findLanguage(languages, language, getUserAlpha2FromLang(alphaCodes, browserLanguage)) ||
        findLanguage(languages, language, getUserAlpha2(alphaCodes)) ||
        findLanguage(
            languages,
            language,
            languages.find(languageTag => languageTag.split('-')[0] === language)?.split('-')[1] as Alpha2Code,
        );
    if (!languageKey) {
        return null;
    }
    return findLanguageInList(languageKey, languages);
}

/**
 * This function performs the following steps to determine the user's language:
 * 1. Check the pathname
 * 2. Examine the browser settings
 * 3. Use DEFAULT_LANGUAGE as a fallback
 * @param supportedLanguages
 */
export function getLanguage(supportedLanguages: LanguageCode[]) {
    return (
        getLanguageFromPath(location.pathname, supportedLanguages) ||
        guessUserLanguage(supportedLanguages) ||
        DEFAULT_LANGUAGE
    );
}

/**
 * This function updates lang attribute in <html> tag
 *
 * This information helps search engines return language specific results,
 * and it is also used by screen readers that switch language profiles
 * to provide the correct accent and pronunciation.
 *
 * @param language
 */
export function updateLangAttribute(language: LanguageCode) {
    document.documentElement.setAttribute('lang', getLanguageFromTag(language));
}
