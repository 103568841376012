import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AccountEncrypted } from 'static/icons/account-encrypted.svg';

import { block } from 'utils/block';

import './AppointmentMobile.scss';

const b = block('appointment-mobile');

export const AppointmentMobileSkeleton: FC<HTMLAttributes<HTMLDivElement>> = ({
    className = '',
    ...rest
}) => {
    const { t } = useTranslation();
    return (
        <article
            className={b({ loading: true }).mix(className)}
            {...rest}
            id="appointment-checkbox-skeleton"
        >
            <AccountEncrypted
                className={b('icon')}
                aria-hidden
            />
            <header className={b('header')}>
                <p className={b('title')} />
                <p className={b('subtitle')}>
                    {t('appointment_checkbox_subtitle')}
                </p>
            </header>
            <p className={b('cancel')}>
                {t('appointment_checkbox_cancel')}
            </p>
        </article>
    );
};
