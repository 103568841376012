import { TenantId } from '@amzn/ring-rse-scheduling-service-typescript-client';

import { InputStatus, PhoneType, Timeout } from 'types/common';
import { LanguageCode } from 'types/language';

export type StateType = {
    personDetailsForm: PersonDetailsFormType;
    smsVerificationForm: SMSVerificationFormType;
    calendar: CalendarType;
    tenantConfig: TenantConfig | null;
};

export type PersonDetailsFormType = {
    firstName: Field<string>;
    lastName: Field<string>;
    phone: Field<Partial<PhoneType>>;
};

export type PersonalDetailsFormFieldType = keyof PersonDetailsFormType;

export type InterestsType = {
    VIDEO: boolean;
    CAMERA : boolean;
    ALARM_SYSTEM: boolean;
    HOME: boolean;
    GENERAL: boolean;
};

export type InterestsKeysType = keyof InterestsType;

export type Field<T> = {
    value?: T;
    status: InputStatus;
    message?: string;
};

export enum  SMSVerificationFormStatus {
    PENDING = 'pending_status',
    PHONE_400 = 'incorrect_phone_error',
    PHONE_429 = 'too_many_attempts_phone_error',
    PHONE_ERROR = 'general_phone_error',
    CODE_ERROR = 'general_code_error',
    SUCCESS = 'success_status',
    DEFAULT = 'default_status',
}

export type ChangePersonDetailsForm = {
    field: PersonalDetailsFormFieldType;
    data: Field<string>;
}

export type CalendarType = {
    startTime?: number;
    endTime?: number;
    source?: string;
    isNoSlots: boolean;
}

export type SMSVerificationFormType = {
    code: Field<string>;
    phone?: string;
    counter: number;
    status: SMSVerificationFormStatus;
    intervalId?: Timeout;
    notification?: SMSVerificationFormNotification;
    isRequestSending: boolean;
}

export type SMSVerificationFormNotification = {
    title: string;
    description: string;
}

export type ChallengeIdType = string | null;

export type Service = {
    locale: LanguageCode,
    serviceId: string,
    timeResourceId: string,
}

export type TenantConfig = {
    tenantId: TenantId,
    services: Service[];
};
