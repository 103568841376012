import { FC, HTMLAttributes, useState } from 'react';
import { Dropdown } from '@amzn/ring-safe-web';

import { LANGUAGES } from 'constants/languages';
import { block } from 'utils/block';

import { LanguageCode } from 'types/language';

import { LanguageSelectorList } from './LanguageSelectorList';

import './LanguageSelector.scss';
import 'flag-icons/css/flag-icons.min.css';

const b = block('language-selector');

type Props = {
    id: string;
    checkedLanguage?: LanguageCode;
    disabled: boolean;
    onChangeCallback: (lang: LanguageCode) => void;
    languageCodes: LanguageCode[];
    className?: string;
    isAbsolute: boolean;
    closeOnClickOutside: boolean;
    closeOnFocusOutside: boolean;
}

export const LanguageSelector: FC<Props & HTMLAttributes<HTMLDivElement>> = (({
    disabled = false,
    languageCodes,
    checkedLanguage,
    id,
    onChangeCallback,
    className = '',
    isAbsolute,
    closeOnClickOutside,
    closeOnFocusOutside,
    ...rest
}) => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(
        languageCodes.find(lang => lang == checkedLanguage) ||
        languageCodes.find(lang => lang == window.navigator.language) ||
        languageCodes[0],
    );

    const onLanguageCheck =  (lang: LanguageCode) => () => {
        setSelectedLanguage(lang);
        onChangeCallback(lang);
        setIsDropdownOpen(false);
    };

    const onDropdownToggle = () => setIsDropdownOpen((currentValue) => !currentValue);

    return (
        <div
            className={b({ disabled }).mix(className)}
            aria-label={id}
            id={id}
            {...rest}
        >
            <Dropdown
                isOpen={isDropdownOpen}
                onToggle={onDropdownToggle}
                closeOnClickOutside={closeOnClickOutside}
                closeOnFocusOutside={closeOnFocusOutside}
                color='primary'
                disabled={disabled}
                id={`${id}-flags`}
                withArrow={true}
                isAbsolute={isAbsolute}
                buttonClassName={b('dropdown-button', { open: isDropdownOpen })}
                className={b('dropdown', { open: isDropdownOpen })}
                aria-label={LANGUAGES[selectedLanguage].name}
                list={(
                    <LanguageSelectorList
                        isOpen={isDropdownOpen}
                        languageCodes={languageCodes}
                        selectedLanguage={selectedLanguage}
                        onCheck={onLanguageCheck}
                        id={`${id}-list`}
                    />
                )}
            >
                <div className={b('label-container')}>
                    <span
                        className={b('button-icon')
                            .mix(`fib fi-${selectedLanguage.split('-')[1].toLowerCase()} fis`)}
                        id={`${id}-button-icon`}
                    />
                    <p className={b('header-icon-label')} >
                        {LANGUAGES[selectedLanguage].languageCode}
                    </p>
                </div>
            </Dropdown>
        </div>
    );
});
