import { FC, HTMLAttributes, ReactNode } from 'react';

import { block } from 'utils/block';

import './Page.scss';

const b = block('page');

type Props = {
    children: ReactNode;
}

export const Page: FC<Props & HTMLAttributes<HTMLElement>> = ({
    children,
    className = '',
    ...rest
}) => {
    return (
        <section className={b().mix(className)} {...rest}>
            {children}
        </section>
    );
};
