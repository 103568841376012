import { FC, HTMLAttributes } from 'react';

import { block } from 'utils/block';

const b = block('page');

export const PageBody: FC<HTMLAttributes<HTMLElement>> = ({
    children,
    className = '',
    ...rest
}) => {
    return (
        <article
            className={b('content').mix(className)}
            tabIndex={0}
            {...rest}
        >
            {children}
        </article>
    );
};
