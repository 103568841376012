import { useEffect, useRef } from 'react';

/**
 * This hook returns previous defined (not null and undefined) value of the given parameter
 * @param value
 */
export function usePreviousDefined<T>(value: T) {
    const previous = useRef(value);
    useEffect(() => {
        if (value === undefined || value === null) {
            return;
        }
        previous.current = value;
    }, [value]);
    return previous.current;
}
