import { FC, HTMLAttributes } from 'react';

import { block } from 'utils/block';

import './Page.scss';

const b = block('page');


export const PageIllustration: FC<HTMLAttributes<HTMLDivElement>> = ({
    className = '',
    children,
    ...rest
}) => {
    return(
        <div className={b('illustration').mix(className)} {...rest}>
            <div className={b('container')}>
                {children}
            </div>
        </div>
    );
};
