import { FC, HTMLAttributes, memo } from 'react';

import { block } from 'utils/block';

import './Calendar.scss';

const b = block('calendar');

const WEEK_LENGTH = 7;

type Props = {
    countOfSlots: number;
    numOfWeeksToShow: number;
    dateString?: string;
    isHidden: boolean;
}

export const CalendarSkeleton: FC<Props & HTMLAttributes<HTMLDivElement>> = memo(({
    countOfSlots,
    numOfWeeksToShow,
    className = '',
    dateString,
    isHidden,
    ...rest
}) => {
    const date = dateString ? new Date(dateString): new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const countOfDays = new Date(year, month, 0).getDate();
    const countOfDaysInPreviousMonth = new Date(year, month + 1, 0).getDate();
    const weekDay = date.getDay();
    const firstDay = new Date(year, month, day - weekDay).getDate();
    const firstDayOfSecondWeek = (WEEK_LENGTH + firstDay) % countOfDays;

    return (
        <section className={b({ skeleton: true, hidden: isHidden }).mix(className)} {...rest}>
            <table className={b('table')}>
                <thead>
                    <tr>
                        <th className={b('month')}>
                            {date.toLocaleString('default', { month: 'long' })}
                        </th>
                    </tr>
                    <tr className={b('week')}>
                        <th className={b('week-day')}>SUN</th>
                        <th className={b('week-day')}>MON</th>
                        <th className={b('week-day')}>TUE</th>
                        <th className={b('week-day')}>WED</th>
                        <th className={b('week-day')}>THU</th>
                        <th className={b('week-day')}>FRI</th>
                        <th className={b('week-day')}>SAT</th>
                    </tr>
                </thead>
                <tbody className={b('body')}>
                    <tr className={b('days-row')}>
                        {new Array(WEEK_LENGTH).fill(0).map((v, i) => (
                            <td className={b('day')} key={`week-1-${i}`}>
                                {(i + firstDay) % countOfDaysInPreviousMonth}
                            </td>
                        ))}
                    </tr>
                    {new Array(numOfWeeksToShow - 1).fill(0).map((v, i) => (
                        <tr key={`week-${i}`} className={b('days-row')}>
                            {new Array(WEEK_LENGTH).fill(0).map((v, j) => (
                                <td className={b('day')} key={`week-${i}-${j}`}>
                                    {(WEEK_LENGTH * i + firstDayOfSecondWeek + j) % countOfDays || countOfDays}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
                <tfoot className={b('footer')} />
            </table>
            <section className={b('slots')}>
                {new Array(countOfSlots).fill(0).map((v, i) => (
                    <div className={b('slot')} key={`slot-${i}`} />
                ))}
            </section>
        </section>
    );
});
