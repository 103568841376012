import { View } from 'types/common';

const MAX_TABLET_WIDTH = 890;
const MAX_MOBILE_WIDTH = 460;

export function getView(width: number) {
    if (width <= MAX_MOBILE_WIDTH) {
        return View.mobile;
    }
    if (width <= MAX_TABLET_WIDTH) {
        return View.tablet;
    }
    return View.laptop;
}

/**
 * This function tests provided UserAgent against Known Mobile User Agents
 */
export function isMobile() {
    return /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|webOS|Opera Mini/
        .test(window.navigator.userAgent || window.navigator.vendor || window.opera);
}

/**
 * This function detects ios devices
 * But it doesn't cover all cases
 * @TODO improve implementation
 * tested with XCode Simulator
 */
export function isiOS() {
    return (
        // The !window.MSStream is to not incorrectly detect IE11:
        // https://www.neowin.net/news/ie11-fakes-user-agent-to-fool-gmail-in-windows-phone-81-gdr1-update/
        (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream)
        // userAgentData?.platform - experimental, but supported by a few modern browsers
        // navigator?.platform - deprecated, not supported by a few modern browsers
        || (navigator.userAgentData?.platform || navigator?.platform === 'MacIntel' && navigator.maxTouchPoints > 1) // iPad >= 13
    );
}
