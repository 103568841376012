import { block } from 'utils/block';
import { getConsentBannerElement } from 'utils/consentManager';

/**
 * This function moves up the "root" container and the current page footer (Footer component)
 * to free up space for consentManagerBanner
 * @private
 */
export function onConsentBannerChange() {
    const rootContainer = getRootContent();
    const { padding, margin } = getButtonIndents(rootContainer);
    const consentBannerHeight = getConsentBannerElement()?.offsetHeight || 0;
    rootContainer.style.paddingBottom = `${padding - onConsentBannerChange.previousConsentBannerHeight + consentBannerHeight}px`;
    rootContainer.style.marginBottom = `${margin + onConsentBannerChange.previousConsentBannerHeight - consentBannerHeight}px`;
    const pageFooter = getFooter();
    if (pageFooter) {
        pageFooter.style.bottom = `${consentBannerHeight}px`;
    }
    onConsentBannerChange.previousConsentBannerHeight = consentBannerHeight;
}
onConsentBannerChange.previousConsentBannerHeight = 0;


export function onFooterChange() {
    const rootContainer = getRootContent();
    const { padding, margin } = getButtonIndents(rootContainer);
    const pageFooter = getFooter();
    if (!pageFooter) {
        return;
    }
    const footerHeight = pageFooter.clientHeight;
    rootContainer.style.paddingBottom = `${padding - onFooterChange.previousFooterHeight + footerHeight}px`;
    rootContainer.style.marginBottom = `${margin  + onFooterChange.previousFooterHeight - footerHeight}px`;
    onFooterChange.previousFooterHeight = footerHeight;
    if (pageFooter && getConsentBannerElement() && onConsentBannerChange.previousConsentBannerHeight) {
        pageFooter.style.bottom = `${onConsentBannerChange.previousConsentBannerHeight}px`;
    }
}
onFooterChange.previousFooterHeight = 0;


/**
 * This function removes
 */
export function onContentBannerRemove() {
    const rootContainer = getRootContent();
    const { padding, margin } = getButtonIndents(rootContainer);
    const contentBannerHeight = getConsentBannerElement()?.offsetHeight || onConsentBannerChange.previousConsentBannerHeight;
    rootContainer.style.paddingBottom = `${padding - contentBannerHeight}px`;
    rootContainer.style.marginBottom = `${margin + contentBannerHeight}px`;
    const pageFooter = getFooter();
    if (pageFooter) {
        pageFooter.style.removeProperty('bottom');
    }
}

/**
 * This function returns the main container of application,
 * The container, in which React renders all content
 */
export function getRootContainer(): HTMLElement {
    return document.getElementById('root')!;
}

/**
 * This function returns the first child of the RootContainer
 * It's needed to support transform behaviour
 *
 * Details:
 * Modal should not be nested in an element with a transform property, because of
 * any element with transform, filter or perspective property will act as
 * a containing block to all its descendants, including the elements whose position is set to fixed
 * https://dev.to/salilnaik/the-uncanny-relationship-between-position-fixed-and-transform-property-32f6
 */
export function getRootContent(): HTMLElement {
    return document.getElementById('root-content')!;
}

export function getFooter() {
    return (document.getElementsByClassName(block('footer')())?.[0] || null) as HTMLElement | null;
}

/**
 * This function returns button indents of the element:
 * padding and margin in numerical format
 * @param element
 */
export function getButtonIndents(element: HTMLElement): {padding: number; margin: number} {
    const { paddingBottom, marginBottom } = getComputedStyle(element);
    const padding = Number.parseInt(paddingBottom || '0');
    const margin = Number.parseInt(marginBottom || '0');
    return ({ padding, margin });
}
