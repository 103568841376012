import { FC, HTMLAttributes, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Button, NotificationList, useNotificationQueue } from '@amzn/ring-safe-web';
import { useRoute } from 'hooks/useRoute';
import houseIntercom from 'static/images/house-intercom.png';
import intercom from 'static/images/intercom.png';
import mobileIntercom from 'static/images/mobile-intercom.png';

import { FooterPortal } from 'components/Footer';
import { block } from 'utils/block';
import { getRootContainer } from 'utils/layout';

import { ROUTE } from 'types/route';

import './WelcomePage.scss';

const b = block('welcome-page');

export const WelcomePage: FC<HTMLAttributes<HTMLDivElement>> = ({
    className = '',
    ...rest
}) => {
    const { changeRoute } = useRoute();
    const { t }  = useTranslation();
    const notifications = useNotificationQueue();

    useEffect(() => {
        return () => notifications.removeAll();
    }, []);

    const onButtonClick = () => changeRoute(ROUTE.CONTACT_INFORMATION);

    return (
        <section className={b().mix(className)} {...rest}>
            {createPortal(<NotificationList className={b('notifications')} />, getRootContainer())}
            <h1 className={b('title')}>
                {t('pages_welcome_title')}
            </h1>
            <h2 className={b('subtitle')}>
                {t('pages_welcome_subtitle')}
            </h2>
            <section className={b('services')}>
                <article className={b('service')}>
                    <img aria-hidden src={intercom} />
                    <h1 className={b('header')}>{t('pages_welcome_service1')}</h1>
                </article>
                <article className={b('service')}>
                    <img aria-hidden src={mobileIntercom} />
                    <h1 className={b('header')}>{t('pages_welcome_service2')}</h1>
                </article>
                <article className={b('service')}>
                    <img aria-hidden src={houseIntercom} />
                    <h1 className={b('header')}>{t('pages_welcome_service3')}</h1>
                </article>
                <FooterPortal container={getRootContainer()}>
                    <Button
                        color='primary'
                        size='m'
                        variant='filled'
                        onClick={onButtonClick}
                        id="welcome-page-redirect-button"
                        className={b('button')}
                    >
                        {t('pages_welcome_button')}
                    </Button>
                </FooterPortal>
            </section>
        </section>
    );
};
