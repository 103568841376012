import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@amzn/ring-safe-web';
import { useLanguage } from 'hooks/useLanguage';
import { ReactComponent as CheckCircle } from 'static/icons/check-circle.svg';

import { block } from 'utils/block';
import { getDateString } from 'utils/time';

import './Appointment.scss';

const b = block('appointment');

type Props = {
    startTime: number;
    appointmentId: string;
    onClick: () => void;
    checked: boolean;
}

export const Appointment: FC<Props & HTMLAttributes<HTMLDivElement>> = ({
    startTime,
    appointmentId,
    checked,
    onClick,
    ...rest
}) => {
    const { t } = useTranslation();
    const { language } = useLanguage();
    const appointmentDate = new Date(startTime);
    const date = getDateString(appointmentDate, language);
    return (
        <Checkbox
            onClick={onClick}
            checked={checked}
            position='right'
            id={`appointment-checkbox-${appointmentId}`}
            key={appointmentId}
            withInputCheckbox={false}
            className={b()}
            icon={<CheckCircle aria-hidden />}
            {...rest}
        >
            <div
                className={b('content')}
                data-heap-redact-text={true}
            >
                <p className={b('title')}>
                    {date}
                </p>
                <p className={b('subtitle')}>
                    {t('appointment_checkbox_subtitle')}
                </p>
            </div>
        </Checkbox>
    );
};
