export type CalendarEventBasic = {
    timezone: string;
    startTime: string; // required format for dates: YYYYMMDDTHHmmSSZ/YYYYMMDDTHHmmSSZ
    endTime: string; // required format for dates: YYYYMMDDTHHmmSSZ/YYYYMMDDTHHmmSSZ
    location: string;
    title: string;
    description: string;
}

export type GoogleCalendarEvent = CalendarEventBasic; // can be expanded with optional fields

export type YahooCalendarEvent = CalendarEventBasic; // can be expanded with optional fields

export type OutlookCalendarEvent = CalendarEventBasic; // can be expanded with optional fields

export type ICSCalendarEvent = CalendarEventBasic; // can be expanded with optional fields

export enum CalendarType {
    GOOGLE= 'Google',
    ICAL = 'iCal',
    OUTLOOK = 'Outlook',
    YAHOO = 'Yahoo',
    APPLE = 'Apple',
    OFFICE_365 = 'Microsoft Office 365',
}

export type CalendarEvent = {
    startTime: number;
    endTime: number;
    title: string;
    timezone: string;
    location?: string;
    description?: string;
}
