import { currentStage, STAGE } from 'constants/stage';
import { getCookie } from 'utils/cookies';
import { getLanguageFromPath } from 'utils/language';

import { AxiosError, AxiosPromise, AxiosResponse, SearchParams } from 'types/common';
import { Cookies } from 'types/cookies';
import { ROUTE } from 'types/route';
import { Tenant } from 'types/url';

/**
 * This function returns id of appointment from search parameters of the given location.search
 * @param search
 */
export function getAppointmentId(search: string): string | null {
    return new URLSearchParams(search).get('id');
}

/**
 * This function returns base origin for backend API
 */
export function getBaseApiPathOrigin(): string {
    return `${host || STAGE[currentStage].endpoints || window.location.origin}`;
}

/**
 * This function constraints api url with passed relativePath
 * @param relativePath
 */
export function getApiURL(relativePath: string) {
    return getBaseApiPathOrigin() + relativePath;
}

/**
 * This function wraps the axios request to intercept the error message and add it as a response parameter
 * --
 * The problem is that the axios request throws an error when the server returns status !== ok
 * This wrapper converts that format into a usual HTTP response with an error message and data in one object
 * {
 *      data: T;
 *      status: number;
 * }
 * @param request
 */
export async function getResponseData<T>(request: AxiosPromise<T> ): Promise<AxiosResponse<T>> {
    try {
        return await request;
    } catch (e) {
        return (e as AxiosError<T>).request;
    }
}

/**
 * This function splits a given URL to:
 *                                          |______search______|
 * |_____________originAndPath______________| |__searchParams__|
 * |                                        | |                |
 * https://my.site.com:8080/path/to/something?param1=1&param2=2
 * @param url
 */
export function splitUrl(url: string) {
    const matchUrl = url.match(/(.*?)(\?(.*?))?\/?$/);
    return {
        originAndPath: matchUrl?.[1] || undefined,
        search: matchUrl?.[2],
        searchParams: matchUrl?.[3],
    };
}

/**
 * This function creates the URL by adding parameters to the existing 'base' url
 * @param base
 * @param params
 */
export function getUrlWithParams(base: string, params: SearchParams) {
    const truthyParamsEntries = Object.entries(params).filter((param) => param[1]) as [string, string][];

    if (truthyParamsEntries.length === 0) {
        return base;
    }

    const { searchParams, originAndPath } = splitUrl(base);

    const searchParamsObj = new URLSearchParams(searchParams);

    const finalSearchParams = decodeURIComponent(truthyParamsEntries.reduce((acc, [key, value]) => {
        searchParamsObj.delete(key);
        searchParamsObj.append(key, value);
        return searchParamsObj;
    }, searchParamsObj).toString());

    if (!finalSearchParams) {
        return base;
    }

    return `${originAndPath || ''}?${finalSearchParams}`;
}

/**
 * This function return an url according to the logic:
 *
 * For a new user
 * 1. should return the same url if the current path is "/"
 * 2. should return the same url if the current path is CONTACT_INFORMATION
 * 3. should return the same url if the current path is APPOINTMENT_BOOKING  with conversation`s id
 * 4. should return the same url if the current path is APPOINTMENT_CONFIRMATION
 * 5. should return CONTACT_INFORMATION otherwise
 *
 * For a user with upcoming appointment
 * 1. should return the same url if the current path is APPOINTMENT_BOOKING  with conversation`s id
 * 1. should return APPOINTMENT_CONFIRMATION otherwise
 */
export function getInitialRedirectedUrl(url: string) {
    const { search, pathname, origin, hash } = new URL(url);
    const appointmentId = getAppointmentId(search);
    const tenant = getTenant(pathname);
    const tenantPath = tenant ? `/${tenant}` : '';
    const route = getRoute(pathname);
    const language = getLanguageFromPath(pathname);
    const languagePath = language ? `/${language.toLowerCase()}` : '';


    //should not redirect if it's an appointments url
    if (route === ROUTE.APPOINTMENT_CONFIRMATION) {
        return url;
    }
    //should not redirect if it's a URL for particular appointment
    if (route === ROUTE.APPOINTMENT_BOOKING && appointmentId) {
        return url;
    }
    //if the user has an upcoming appointment, it should always redirect to APPOINTMENT_CONFIRMATION
    //because if the user doesn't have an appointment, then we don't know their name
    if (getCookie(Cookies.hasAppointment)) {
        return `${origin}${tenantPath}${languagePath}${ROUTE.APPOINTMENT_CONFIRMATION}${search}${hash}`;
    }
    if (!route || route === ROUTE.WELCOME) {
        return url;
    }
    //if the user doesn't have an upcoming appointment, it should redirect to CONTACT_INFORMATION
    return `${origin}${tenantPath}${languagePath}${ROUTE.CONTACT_INFORMATION}${search}${hash}`;
}

/**
 * This function returns a route based on a pathname
 * It uses the fact that the route always follows a tenant/language
 * @param pathname
 */
export function getRoute(pathname: string) {
    const tenant = getTenant(pathname);
    const language = getLanguageFromPath(pathname)?.toLowerCase();
    const pathWithoutTenant = tenant ?
        pathname.replace(`/${tenant}`, '') :
        pathname;
    const pathWithoutTenantAndLanguage = language ?
        pathWithoutTenant.replace(`/${language}`, '') :
        pathWithoutTenant;
    return Object.values(ROUTE).find(route => route === pathWithoutTenantAndLanguage);
}

/**
 * This function returns the tenant (or undefined) from the passed pathname.
 * It uses the fact that the tenant always goes first in the path.
 * @param pathname
 */
export function getTenant(pathname: string) {
    const firstPath = pathname.split('/')[1]; //path always starts with "/"
    return Object.values(Tenant).find(tenant => firstPath === tenant);
}

/**
 * This function returns pathname + search + hash from the given url
 * @param url: string
 */
export function getPath(url: string) {
    const { origin } = new URL(url);
    return url.replace(origin, '') || '/';
}

/**
 * This function substitutes the existing route (or any pathname after tenant/language)
 * with the given route in the provided URL
 * @param url
 * @param route
 */
export function replaceRouteInURL(url: string, route: ROUTE) {
    const { pathname, search, hash, origin } = new URL(url);
    const tenant = getTenant(pathname);
    const tenantPath = tenant ? `/${tenant}` : '';
    const language = getLanguageFromPath(pathname)?.toLowerCase();
    const languagePath = language ? `/${language}` : '';
    return origin + tenantPath + languagePath + route + search + hash;
}

/**
 * This function adds the passed in tenant into the url
 * using the fact that a tenant is always the first part in a pathname
 * @param url
 * @param tenant
 */
export function addTenantToUrl(url: string, tenant: Tenant) {
    const { pathname, search, hash, origin } = new URL(url);
    return `${origin}/${tenant}${pathname}${search}${hash}`;
}

/**
 * This function creates a route URL with search parameters and the passed route
 * using language and tenant parsed from the current window.location.pathname
 * @param route
 * @param options
 */
export function createRoute(route: ROUTE, options: SearchParams = {}) {
    const { pathname } = new URL(replaceRouteInURL(window.location.href, route));
    return getUrlWithParams(pathname, options);
}
