export enum Cookies {
    hasAppointment = 'hasAppointment',
    isAuthorized = 'isAuthorized',
}

export type PrivacyCookie = {
    [value in PrivacyCookieValue]: boolean | string;
}

export enum PrivacyCookieValue {
    heap = 'analytics_heap',
    amazonDisplay = 'advertising_amazonDisplay',
    kenshoo = 'advertising_kenshoo',
    sizmek = 'advertising_sizmek',
    optimizely = 'analytics_optimizely',
    yext = 'analytics_yext',
    consentDate = 'consentDate',
    consentUrl = 'consentUrl',
    version = 'version'
}
