import { getFirstErrorFieldName } from 'utils/form';
import { useAppSelector } from 'utils/store';

/**
 * Hook checks that the form has been completed correctly
 */
export function usePersonalDetailsFormCorrect() {
    return useAppSelector(({ personDetailsForm }) => (
        getFirstErrorFieldName(personDetailsForm) === undefined
    ));
}

/**
 * Hook checks if there are any error messages on the form
 */
export function usePersonalDetailsFormHasMessage() {
    return useAppSelector(({ personDetailsForm }) => (
        Object.values(personDetailsForm).some((value) => {
            if (typeof value !== 'object') {
                return false;
            }
            return value.message !== undefined;
        })
    ));
}
