import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { createRoute } from 'utils/url';

import { ROUTE } from 'types/route';

export function useRoute() {
    const history = useNavigate();

    const changeRoute = useCallback((route: ROUTE, options: Record<string, string | null | undefined> = {}) => {
        history(createRoute(route, options));
    }, [history]);

    return { changeRoute };
}
