import { FC, useState } from 'react';
import { Dropdown, DropdownItem, DropdownList } from '@amzn/ring-safe-web';

import { block } from 'utils/block';
import { buildShareUrl, saveICSFile } from 'utils/calendar';
import { capitalizeFirstLetter } from 'utils/common';
import { isiOS, isMobile } from 'utils/view';

import { CalendarEvent, CalendarType } from 'types/calendar';
import { View } from 'types/common';

import { AddToCalendarIcon } from './AddToCalendarIcon';

import './AddToCalendar.scss';

const b = block('add-to-calendar');

type Props = {
    title: string;
    supportedTypes: CalendarType[]
    id: string;
    event: CalendarEvent;
    iCalFileName?: string;
    view: View;
    onClickCallback?: (isOpen: boolean) => void;
    isAbsolute: boolean;
}

export const AddToCalendar: FC<Props> = ({
    title,
    supportedTypes,
    id,
    event,
    iCalFileName,
    view,
    onClickCallback,
    isAbsolute,
    ...props
}) => {

    const [isOpen ,setIsOpen] = useState(false);
    const filteredSupportedTypes = supportedTypes.filter((type) => {
        // Redirect to iCal on mobile devices for OFFICE_365 and OUTLOOK, since the Microsoft web apps are buggy on mobile devices
        // More into: https://github.com/add2cal/add-to-calendar-button/discussions/113
        if (isMobile() && (type == CalendarType.OFFICE_365 || type == CalendarType.OUTLOOK)) {
            return false;
        }
        // Remove APPLE calendar for not Apple devices
        if (!isiOS() && type === CalendarType.APPLE) {
            return false;
        }
        // Remove ICAL for Apple, as they do same things
        if (isiOS() && type === CalendarType.ICAL) {
            return false;
        }
        return true;
    });

    const createCalendarEvent = (event: CalendarEvent, type: CalendarType) => () => {
        const url = buildShareUrl(event, type);
        if ([CalendarType.ICAL, CalendarType.APPLE].includes(type)) {
            saveICSFile(url,
                iCalFileName ||
                event.title.split(' ').map(capitalizeFirstLetter).join(''));
        } else {
            window.open(url, '_blank')?.focus();
        }
    };

    const onToggle = () => {
        onClickCallback?.(!isOpen);
        setIsOpen((currentIsOpen) => !currentIsOpen);
    };

    return (
        <Dropdown
            color='primary'
            className={b({ view })}
            buttonClassName={b('button')}
            isOpen={isOpen}
            onToggle={onToggle}
            closeOnClickOutside={true}
            closeOnFocusOutside={true}
            disabled={false}
            id={id}
            withArrow={false}
            isAbsolute={isAbsolute}
            list={
                <DropdownList isOpen={isOpen} className={b('dropdown')}>
                    {filteredSupportedTypes.map((type) => (
                        <DropdownItem
                            icon={<AddToCalendarIcon calendarType={type} aria-hidden/>}
                            isChecked={false}
                            key={type}
                            onClick={createCalendarEvent(event, type)}
                        >
                            {type}
                        </DropdownItem>
                    ))}
                </DropdownList>
            }
            {...props}
        >
            <AddToCalendarIcon
                calendarType={CalendarType.ICAL}
                className={b('icon')}
                aria-hidden
            />
            <p className={b('title')}>
                {title}
            </p>
        </Dropdown>
    );
};
