import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@amzn/ring-safe-web';
import { ReactComponent as Error } from 'static/images/error.svg';

import { block } from 'utils/block';
import { getCookie } from 'utils/cookies';

import { Cookies } from 'types/cookies';

import './ErrorBoundaryPage.scss';

const b = block('error-boundary-page');

export const ErrorBoundaryPage: FC<HTMLAttributes<HTMLDivElement>> = ({
    className = '',
    ...rest
}) => {
    const { t } = useTranslation();
    const hasAppointment = getCookie(Cookies.hasAppointment);

    const onLeftButtonClick = () => {
        window.location.href = 'https://ring.com/collections/all-products';
    };

    const onRightButtonClick = () => {
        window.location.href = window.location.origin;
    };

    return (
        <div className={b().mix(className)} {...rest}>
            <Error
                className={b('illustration')}
                aria-hidden
            />
            <h1 className={b('title')}>{t('pages_boundary_error_title')}</h1>
            <h2 className={b('subtitle')}>{t('pages_boundary_error_subtitle')}</h2>
            <div className={b('buttons')}>
                <Button
                    color='primary'
                    size='m'
                    variant='outline'
                    onClick={onLeftButtonClick}
                    className={b('button')}
                >
                    {t('pages_error_button_shop')}
                </Button>
                <Button
                    color='primary'
                    size='m'
                    variant='outline'
                    onClick={onRightButtonClick}
                    className={b('button')}
                >
                    {hasAppointment ? t('pages_error_button_appointments') : t('pages_error_button_new_call')}
                </Button>
            </div>
        </div>
    );
};
