import { phone } from 'phone';

import {  formatPhoneNumberForBackend } from 'utils/phone';

import { ErrorMessageProvider,PhoneType } from 'types/common';
import { Field, PersonalDetailsFormFieldType, PersonDetailsFormType } from 'types/state';

//https://code.amazon.com/packages/RingRSESchedulingServiceModel/blobs/f8e39f274823434702cba2b67ccc07372d2db587/--/model/operations/appointments/commons.smithy#L22-L30
const MAX_CHARACTER_LIMIT = 30;
const MIN_CHARACTER_LIMIT = 1;

export const ERROR = {
    MIN: 'min_character_limit_threshold_error',
    MAX: 'max_character_limit_threshold_error',
    PHONE: 'phone_error',
    LATIN: 'character_error',
};


/**
 * Check
 * 1) whether the value is within [min,max] interval
 * 2) whether the value contains only latin letters
 * If so, return undefined,
 * Otherwise, return an error message
 * @param value
 * @param min
 * @param max
 */
export function getErrorForName(value?: string, min = MIN_CHARACTER_LIMIT, max = MAX_CHARACTER_LIMIT): string | undefined {
    if (value === undefined || value.length < min) {
        return ERROR.MIN;
    }
    if (value.length > max) {
        return ERROR.MAX;
    }
    if (!(/^[a-z\s]+$/i).test(value)) {
        return ERROR.LATIN;
    }
    return undefined;
}


/**
 * Check
 * 1) whether the value length less than max
 * 2) whether the value contains only latin letters
 * If so, return undefined,
 * Otherwise, return an error message
 * @param value
 * @param max
 */
export function getErrorForLastName(value?: string, max = MAX_CHARACTER_LIMIT): string | undefined {
    if (value === undefined) {
        return undefined;
    }
    if (value.length > max) {
        return ERROR.MAX;
    }
    if (!(/^[a-z\s]*$/i).test(value)) {
        return ERROR.LATIN;
    }
    return undefined;
}


/**
 * Check whether the value fits phone mask (000) 000 - 0000
 * If so, return undefined,
 * Otherwise, return an error message
 * @param value
 */
export function getErrorForPhone(value?: Partial<PhoneType>): string | undefined {
    if (!value?.number || !value?.code) {
        return ERROR.PHONE;
    }
    if (!phone(formatPhoneNumberForBackend({ number: value.number, code: value.code })).isValid) {
        return ERROR.PHONE;
    }
    return undefined;
}

/**
 * Return an appropriate function to receive an error message for the field
 * @param field
 */
export function getErrorFunction<T>(field: PersonalDetailsFormFieldType): ErrorMessageProvider<T> {
    const map = ({
        firstName: getErrorForName,
        lastName: getErrorForLastName,
        phone: getErrorForPhone,
    });
    return map[field] as ErrorMessageProvider<T>;
}

/**
 * Return Field wrapper around value with appropriate error and status.
 * @param value
 * @param field
 */
export function updateFormField<T>(value: T | undefined, field: PersonalDetailsFormFieldType): Field<T> {
    const message = getErrorFunction(field)(value);
    const status = message ? 'error' : 'default';
    return ({
        status,
        message,
        value,
    });
}

export function getFirstErrorFieldName(personDetailsForm: PersonDetailsFormType): PersonalDetailsFormFieldType | undefined {
    return Object.keys(personDetailsForm).find((key) => {
        return getErrorFunction(key)(personDetailsForm[key].value) !== undefined;
    }) as PersonalDetailsFormFieldType | undefined;
}
