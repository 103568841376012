import { FC, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button, Link } from '@amzn/ring-safe-web';
import { useAppointment } from 'hooks/useAppointment';
import { useCreateAppointment } from 'hooks/useCreateAppointment';
import { useRoute } from 'hooks/useRoute';
import { useUpdateAppointment } from 'hooks/useUpdateAppointment';

import { Calendar } from 'components/Calendar';
import { CalendarNewDeviceOTPModal } from 'components/CalendarNewDeviceOTPModal';
import { FooterPortal } from 'components/Footer';
import { PageBody, PageHeader } from 'components/Page';
import { DisplayLocationContext } from 'content/DisplayLocation';
import { block } from 'utils/block';
import { getRootContainer } from 'utils/layout';
import { useAppSelector } from 'utils/store';
import { createRoute, getAppointmentId, getRoute } from 'utils/url';

import { ROUTE } from 'types/route';

import './MobileCalendarPage.scss';

const b = block('mobile-calendar-page');

const NUMBER_OF_WEEKS = 4;
const COUNT_OF_SLOTS = 28;

export const MobileCalendarPage: FC = () => {
    const location = useLocation();
    const { changeRoute } = useRoute();
    const { t } = useTranslation();
    const appointment = useAppointment();
    const onCreateAppointment = useCreateAppointment();
    const onUpdateAppointment = useUpdateAppointment();
    const appointmentId = getAppointmentId(location.search);
    const hasSource = useAppSelector(state => Boolean(state.calendar.source));
    const { startTime , endTime } = useAppSelector(state => state.calendar);
    const { displayLocation } = useContext(DisplayLocationContext);

    const [isRequestSending, setIsRequestSending] = useState(false);
    const isDaySelectionPage = useMemo(() => {
        return getRoute(location.pathname) === ROUTE.APPOINTMENT_BOOKING;
    }, [displayLocation]);

    const getIsDisabled = () => {
        if (!hasSource) {
            return true;
        }
        if (isDaySelectionPage) {
            return false;
        }
        return (
            //if it's a new user than appointment should be created
            !appointment &&
            //if it's the user with existing appointment, then appointmentId should be in search url parameters
            !(appointmentId && startTime && endTime) ||
            //if any request were sent then button should be disabled
            isRequestSending
        );
    };

    const onButtonClick = async () => {
        if (isDaySelectionPage) {
            changeRoute(ROUTE.APPOINTMENT_BOOKING_SLOTS, { id: appointmentId });
            return;
        }
        setIsRequestSending(true);
        if (appointmentId) {
            await onUpdateAppointment();
        } else {
            await onCreateAppointment();
        }
        setIsRequestSending(false);
    };

    return (
        <PageBody className={b({ slots: !isDaySelectionPage })}>
            <CalendarNewDeviceOTPModal />
            <PageHeader
                title={t('pages_appointment_booking_title')}
            />
            <Link
                className={b('link', { visible: !isDaySelectionPage })}
                type='internal'
                link={createRoute(ROUTE.APPOINTMENT_BOOKING, { id: appointmentId })}
                color='primary'
                size='m'
                id="mobile-calendar-page-change-day-link"
            >
                {t('pages_appointment_change_day')}
            </Link>
            <div className={b('calendar-container')}>
                <Calendar
                    id='mobile-calendar-page-calendar'
                    numOfWeeksToShow={NUMBER_OF_WEEKS}
                    countOfSlots={COUNT_OF_SLOTS}
                    className={b('calendar')}
                />
            </div>
            <FooterPortal container={getRootContainer()}>
                <Button
                    color='primary'
                    size='m'
                    variant='filled'
                    onClick={onButtonClick}
                    id="mobile-calendar-page-redirect-button"
                    disabled={getIsDisabled()}
                >
                    {t('pages_appointment_booking_button')}
                </Button>
            </FooterPortal>
        </PageBody>
    );
};
