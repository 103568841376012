//https://developers.heap.io/reference/client-side-apis-overview#typescript-type-definitions
export interface Heap {
    addEventProperties: (properties: HeapEventProperties) => void;
    addUserProperties: (properties: HeapEventProperties) => void;
    appid: string;
    clearEventProperties: () => void;
    config: unknown;
    identify: (identity: string) => void;
    identity: string | null;
    load: (appId: string, properties?: HeapEventProperties) => void;
    loaded: boolean;
    removeEventProperty: (property: string) => void;
    resetIdentity: () => void;
    track: (event: HeapEventName, properties?: HeapEventProperties) => void;
    userId: string;
}

export enum HeapEventName {
    Authenticated = 'Authenticated',
    NewDeviceModalOpen = 'NewDeviceModalOpen',
}

// key-value pairs to be associated with an event.
export type HeapEventProperties = Record<string | number, string | number>

export type HeapRewriteProps = {
    event_properties?: {
        href?: string;
        hierarchy?: string;
        [parameter: string]: unknown;
    };
    pageview_properties?: {
        path?: string;
        hash?: string;
        previous_page?: string;
        query?: string;
        [parameter: string]: unknown;
    };
    session_properties?: {
        path?: string;
        hash?: string;
        referrer?: string;
        query?: string;
    };
}
