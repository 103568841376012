import { Countries } from 'types/country';

// checked with https://howtocall.org/
export const COUNTRIES: Countries = {
    GB: {
        nameLocal: 'Great Britain',
        phoneCode: 44,
        name: 'United Kingdom',
        region: 'Western Europe',
        mask: '(000) 000-0000',
    },
    FR: {
        nameLocal: 'France',
        phoneCode: 33,
        name: 'France',
        region: 'Western Europe',
        mask: '0 00 00 00 00',
    },
    IE: {
        nameLocal: 'Ireland, Éire',
        phoneCode: 353,
        name: 'Ireland',
        region: 'Western Europe',
        mask: '(000) 000-000',
    },
    DE: {
        nameLocal: 'Deutschland',
        phoneCode: 49,
        name: 'Germany',
        region: 'Western Europe',
        mask: '000000000000',
    },
    IT: {
        nameLocal: 'Italia',
        phoneCode: 39,
        name: 'Italy',
        region: 'Southern Europe',
        mask: '(000) 0000-000',
    },
    NL: {
        nameLocal: 'Nederland',
        phoneCode: 31,
        name: 'Netherlands',
        region: 'Western Europe',
        mask: '00-000-0000',
    },
    ES: {
        nameLocal: 'España',
        phoneCode: 34,
        name: 'Spain',
        region: 'South West Europe',
        mask: '(000) 000-000',
    },
    AT: {
        nameLocal: 'Österreich',
        phoneCode: 43,
        name: 'Austria',
        region: 'Central Europe',
        mask: '(000) 000-0000',
    },
} as const;
