import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLanguage } from 'hooks/useLanguage';

import { DEFAULT_TENANT } from 'constants/common';
import { replaceLanguageInUrl } from 'utils/language';
import {
    addTenantToUrl,
    getInitialRedirectedUrl,
    getPath,
    getTenant,
} from 'utils/url';

/**
 * This hook is responsible for redirection user when it's the first app mounting
**/
export const useInitialRedirect = () => {
    const history = useNavigate();
    const { language } = useLanguage();

    useEffect(() => {
        const url = location.href;
        const initialRedirectedUrl = getInitialRedirectedUrl(url);
        const languageUrl = replaceLanguageInUrl(initialRedirectedUrl, language);
        const tenant = getTenant(location.pathname);
        const newUrl = tenant ? languageUrl : addTenantToUrl(languageUrl, DEFAULT_TENANT);
        if (newUrl !== url) {
            history(getPath(newUrl));
        }
    }, [language]);
};
