export type InputStatus = 'error' | 'default';

/**
 * A function which uses this type has to check if the value is valid.
 * If so, return undefined,
 * Otherwise return an error message
 */
export type ErrorMessageProvider<T> = (value?: T) => string | undefined;

export type PhoneType = {
    code: number,
    number: string,
}

export type Timeout = ReturnType<typeof setTimeout> //positive integer;

export type AsyncFunction<A extends unknown[], R> = (...args: A) => Promise<R>;

export enum Stage {
    alpha = 'alpha',
    beta = 'beta',
    gamma = 'gamma',
    prod = 'prod',
    test = 'test',
}

export enum View {
    mobile = 'mobile',
    tablet = 'tablet',
    laptop = 'laptop',
}

export interface AxiosError<T> extends Error {
    code?: string;
    request: AxiosResponse<T>;
    isAxiosError: boolean;
    toJSON: () => object;
}

export interface AxiosResponse<T>  {
    data: T;
    status: number;
    statusText: string;
}

export type AxiosPromise<T> = Promise<AxiosResponse<T>>;

export type CookieType = {
    expires: string | Date,
   'max-age': number,
    domain: string,
    path: string,
    secure: boolean,
    samesite: 'lax' | 'strict' | 'none',
}

export type SearchParams = Record<string, string | null | undefined>;
