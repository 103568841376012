import { Timeout } from 'types/common';
import { SMSVerificationFormStatus } from 'types/state';

const CODE_LENGTH = 6;

/**
 * This function returns whether SMSVerificationComponent submit button should be disabled.
 * Returns true - if it should be disabled
 * @param status
 * @param isRequestSending
 * @param code
 */
export function getIsSubmitButtonDisabled(status: SMSVerificationFormStatus, isRequestSending: boolean, code = ''): boolean {
    if (isRequestSending) {
        return true;
    }
    return code.length !== CODE_LENGTH || SMSVerificationFormStatus.PENDING !== status;
}

/**
 * This function returns whether SMSVerificationComponent reset button should be disabled.
 * Returns true - if it should be disabled
 * @param status
 * @param isRequestSending
 * @param intervalId
 */
export function getIsResetButtonDisabled(status: SMSVerificationFormStatus, isRequestSending: boolean, intervalId?: Timeout): boolean {
    if (isRequestSending || intervalId) {
        return true;
    }

    return [
        SMSVerificationFormStatus.PHONE_400,
        SMSVerificationFormStatus.DEFAULT,
        SMSVerificationFormStatus.SUCCESS,
    ].includes(status);
}

/**
 * This function returns whether SMSVerificationComponent code input should be disabled.
 * Returns true - if it should be disabled
 * @param status
 */
export function getInputDisabled(status: SMSVerificationFormStatus): boolean {
    return ![
        SMSVerificationFormStatus.PENDING,
        SMSVerificationFormStatus.CODE_ERROR,
        SMSVerificationFormStatus.DEFAULT,
    ].includes(status);
}
