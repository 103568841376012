/**
 * Module for automatic generation of class names according to BEM
 * Block Element Modifier is a methodology that helps you to create reusable components and code sharing in front-end development
 */

import { isString } from 'utils/common';

export interface BemSettings {
    el: string
    mod: string
    modValue: string
    prefix: string;
}

export interface BemMods {
    [mod: string]: string | boolean;
}

export type BemItem = {
    mix(...mix: string[]): BemItem & string;
    toString(): string;
};

interface BemContext {
    name: string
    mods: BemMods
    mixes: string[]
}

export type Block = BemItem & {
    (...elemNameOrMods: (string | BemMods)[]): BemItem & string
} & string;

const defaultSettings: BemSettings = {
    el: '__',
    mod: '_',
    modValue: '_',
    prefix: 'rse__',
};

const isBemMods = (nameOrMods: string | BemMods): nameOrMods is BemMods => (
    typeof nameOrMods !== 'string'
);

/**
 * Add new mixes to context of the block
 * @param context
 * @param settings
 */
const mix = (context: BemContext, settings = defaultSettings) => (...mixes: string[]): BemItem & string => {
    const copiedContext = { ...context };

    copiedContext.mixes = copiedContext.mixes.concat(mixes);
    return createBemItem(copiedContext, settings);
};

/**
 * Create a string from context according to BEM rules
 * @param context
 * @param settings
 */
const toString = (settings: BemSettings, context: BemContext) => {
    const { name, mods, mixes } = context;
    let classes: string[] = [name];

    // Add list of modifiers
    if (mods) {
        classes = classes.concat(
            Object.keys(mods)
                .filter(key => mods[key]) // Don't add modifiers with falsy values
                .map(key => {
                    const value = mods[key];
                    // Modifier with only name
                    if (value === true) {
                        return name + settings.mod + key;
                    }
                    return (name + settings.mod + key + settings.modValue + value);
                }),
        );
    }

    // Add mixes
    if (mixes) {
        classes = classes.concat(mixes);
    }

    return classes.join(' ');
};

/**
 * Create an object BemItem,
 * with method mix and custom string representation
 * @param context
 * @param settings
 */
function createBemItem(context: BemContext, settings: BemSettings): BemItem & string {
    return {
        mix: mix(context, settings),
        toString: toString.bind(null, settings, context),
    } as BemItem & string;
}

/**
 * Bounds an element name and mods,
 * Return a function that generates class names for components
 * @param context
 * @param settings
 */
export const createBemBlock = (context: BemContext, settings = defaultSettings) => (...args: (string | BemMods)[]): BemItem => {
    if (!args.length) {
        return createBemItem(context, settings);
    }

    const copiedContext = { ...context };

    copiedContext.name = args
        .filter(isString)
        .reduce((acc, name) => acc + settings.el + name, copiedContext.name);

    copiedContext.mods = args
        .filter(isBemMods)
        .reduce((acc, mods) => ({ ...acc, ...mods }), copiedContext.mods);

    return createBemItem(copiedContext, settings);
};

/**
 * Bounds a block name
 * Return a function that generates a block wrapper
 * @param blockName
 * @param settings
 */
export const block = (
    blockName: string,
    settings = defaultSettings,
) => {
    if (!blockName.trim()) {
        throw new Error('Block name should be non-empty');
    }

    const name = settings.prefix + blockName.trim();

    const context: BemContext = {
        name,
        mods: {},
        mixes: [],
    };

    const boundBlock = createBemBlock(context, settings) as Block;
    boundBlock.mix = mix(context, settings);
    return boundBlock;
};
