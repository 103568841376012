import { FC, HTMLAttributes } from 'react';

import { block } from 'utils/block';

import './Header.scss';

const b = block('header');

type Props = {
    className?: string;
    header: string;
}

export const Header: FC<Props & HTMLAttributes<HTMLDivElement>> = ({
    children,
    header,
    className = '',
    ...rest
}) => {
    return (
        <header className={b().mix(className)} {...rest}>
            <h1 className={b('h1')}>
                {header}
            </h1>
            {children}
        </header>
    );
};
