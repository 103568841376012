import { downloadFile } from 'utils/file';
import { formatDateForCalendarEvent } from 'utils/time';
import { isiOS, isMobile } from 'utils/view';

import {
    CalendarEvent,
    CalendarEventBasic,
    CalendarType,
    GoogleCalendarEvent,
    ICSCalendarEvent,
    OutlookCalendarEvent,
    YahooCalendarEvent,
} from 'types/calendar';

export function escapeICSDescription(description: string) {
    return description.replace(/(\r?\n|<br ?\/?>)/g, '\\n');
}

/**
 * This function takes an event object and returns a Google Calendar Event URL
 * Info: https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/google.md
 */
export function googleShareUrl({
    description,
    endTime,
    location,
    startTime,
    timezone,
    title,
}: GoogleCalendarEvent) {
    return (
        `https://calendar.google.com/calendar/u/0/r/eventedit?dates=${startTime}/${endTime}${timezone &&
        `&ctz=${timezone}`}&location=${location}&text=${title}&details=${description}`
    );
}

/**
 * This function takes an event object and returns a Yahoo Calendar Event URL
 * Info: https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/yahoo.md
 */
export function yahooShareUrl({
    title,
    startTime,
    description,
    location,
    endTime,
}: YahooCalendarEvent) {
    return (
        `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${
            title}&st=${startTime}&et=${endTime}&desc=${description}&in_loc=${location}`
    );
}

/**
 * This function takes an event object and returns an Outlook Calendar Event URL
 * Info: https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/outlook-web.md
 */
export function outlookShareUrl({
    title,
    startTime,
    description,
    location,
    endTime,
}: OutlookCalendarEvent) {
    return (
        `https://outlook.live.com/calendar/0/deeplink/compose?path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&subject=${
            title}&startdt=${startTime}&enddt=${endTime}&body=${description}&location=${location}`
    );
}

/**
 * This function takes an event object and returns an Office Outlook Calendar Event URL
 * Info: https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/outlook-web.md
 */
export function office365ShareUrl({
    title,
    startTime,
    description,
    location,
    endTime,
}: OutlookCalendarEvent) {
    return (
        `https://outlook.office.com/calendar/0/deeplink/compose?path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&subject=${
            title}&startdt=${startTime}&enddt=${endTime}&body=${description}&location=${location}`
    );
}

/**
 * This function takes an event object and returns an array to be downloaded as ics file
 * See specs at: https://www.rfc-editor.org/rfc/rfc5545.html
 */
export function buildShareFile({
    description,
    endTime,
    location ,
    startTime,
    timezone,
    title,
}: ICSCalendarEvent) {
    const content = [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        'METHOD:PUBLISH',
        `DTSTART;TZID=${encodeURIComponent(timezone)}:${startTime}`, // example: DTSTART;TZID=America/New_York:19980119T020000
        `DTEND;TZID=${encodeURIComponent(timezone)}:${endTime}`, // example: DTEND;TZID=America/New_York:19980119T030000
        `SUMMARY:${title}`,
        `DESCRIPTION:${escapeICSDescription(description)}`,
        `LOCATION:${location}`,
        'END:VEVENT',
        'END:VCALENDAR',
    ].join('\r\n');
    return isMobile() ? encodeURI(`data:text/calendar;charset=utf8,${content}`) : content;
}

function encodeIfNecessary(string: string, type: CalendarType) {
    const withoutEncode = [CalendarType.ICAL, CalendarType.OUTLOOK,  CalendarType.APPLE].includes(type);
    return withoutEncode ? string : encodeURIComponent(string);
}

/**
 * This function convert CalendarEvent to CalendarEventBasic that can be consumed by real calendars
 */
function prepareCalendarEventBasic({
    location = '',
    description = '',
    title,
    timezone,
    endTime,
    startTime,
}: CalendarEvent, type: CalendarType): CalendarEventBasic {
    if (type === CalendarType.GOOGLE && isiOS() && location) {
        // Bug: Google calendar sometimes doesn't recognize location on IOS
        description = description?.concat(`\n\nLocation: ${location}`);
    }

    return ({
        description: encodeIfNecessary(
            [CalendarType.OUTLOOK, CalendarType.OFFICE_365].includes(type) ? description.replace(/\n/g, '<br>') : description,
            type,
        ),
        location: encodeIfNecessary(location, type),
        startTime: encodeIfNecessary(formatDateForCalendarEvent(startTime, type), type),
        endTime: encodeIfNecessary(formatDateForCalendarEvent(endTime, type), type) ,
        timezone: encodeIfNecessary(timezone, type),
        title: encodeIfNecessary(title, type),
    });
}

/**
 * This function creates a link to redirect or download a calendar event
 */
export function buildShareUrl(event: CalendarEvent, type: CalendarType) {

    const data = prepareCalendarEventBasic(event, type);

    switch (type) {
        case CalendarType.GOOGLE:
            return googleShareUrl(data);
        case CalendarType.YAHOO:
            return yahooShareUrl(data);
        case CalendarType.OUTLOOK:
            return outlookShareUrl(data);
        case CalendarType.OFFICE_365:
            return office365ShareUrl(data);
        case CalendarType.ICAL:
        case CalendarType.APPLE:
            return buildShareFile(data);
        default:
            return buildShareFile(data);
    }
}


export function saveICSFile(href: string, filename: string) {
    const blob = new Blob([href], { type: 'text/calendar;charset=utf-8' });
    downloadFile(blob, `${filename}.ics`);
}
