import { addZerosAtStart } from 'utils/common';

import { CalendarType } from 'types/calendar';
import { LanguageCode } from 'types/language';

/**
 * This function returns the string representation of the passed date in the format:
 * "Monday 5 June 2023 at 13:00"
 * @param date
 * @param locale
 */
export function getDateString(date: Date, locale: LanguageCode): string {
    let mediumTime = new Intl.DateTimeFormat(locale, {
        timeStyle: 'short',
        dateStyle: 'full',
    });
    return mediumTime.format(date);
}

/**
 * This function returns the string representation of the passed date in the format:
 * "Saturday 10:00"
 * @param date
 * @param locale
 */
export function getShortDateString(date: Date, locale: LanguageCode): string {
    let mediumTime = new Intl.DateTimeFormat(locale, {
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
    });
    return mediumTime.format(date);
}

/**
 * This function creates a new date that is "duration" minutes later than the "startTime"
 * @param startTime
 * @param shift
 */
export function getTimestampWithShiftedMinutes(startTime: number, shift: number): number {
    const date = new Date(startTime);
    date.setMinutes(date.getMinutes() + shift);
    return Number(date);
}

/**
 * https://attacomsian.com/blog/javascript-current-timezone#:~:text=To%20get%20the%20current%20browser's,UTC%20time%20and%20local%20time
 */
export function getCurrentIANATimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * This function return { year: YYYY, month: MM, day: DD, hours: HH, minutes: MM, seconds: SS } in UTC timezone
 */
export function getUTCDate(date: Date) {
    return {
        year: date.getUTCFullYear(),
        month: addZerosAtStart(date.getUTCMonth() + 1),
        day: addZerosAtStart(date.getUTCDate()),
        hours: addZerosAtStart(date.getUTCHours()),
        minutes:  addZerosAtStart(date.getUTCMinutes()),
        seconds: addZerosAtStart(date.getUTCSeconds()),
    };
}

/**
 * This function return { year: YYYY, month: MM, day: DD, hours: HH, minutes: MM, seconds: SS } in user's timezone
 */
export function getDate(date: Date) {
    return {
        year: date.getFullYear(),
        month: addZerosAtStart(date.getMonth() + 1),
        day: addZerosAtStart(date.getDate()),
        hours: addZerosAtStart(date.getHours()),
        minutes:  addZerosAtStart(date.getMinutes()),
        seconds: addZerosAtStart(date.getSeconds()),
    };
}

/**
 * This function converts timestamp to date in YYYYMMDDTHHmmSSZ format for general calendars
 * And in YYYY-MM-DDTHH:mm:SSZ format for Microsoft calendars
 */
export function formatDateForCalendarEvent(timestamp: number, type: CalendarType): string  {
    const date = new Date(timestamp);
    const { year, day, hours, seconds, month, minutes } = type === CalendarType.YAHOO ? getDate(date) : getUTCDate(date);
    switch (type) {
        case CalendarType.OUTLOOK:
        case CalendarType.OFFICE_365: {
            return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
        }
        default: {
            return `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
        }
    }
}
