import { useTranslation } from 'react-i18next';
import { useNotificationQueue } from '@amzn/ring-safe-web';
import { updateAppointment } from 'api/appointment';

import { HttpStatusCode } from 'constants/httpCodes';
import { useAppSelector } from 'utils/store';
import { getCurrentIANATimeZone } from 'utils/time';
import { getAppointmentId } from 'utils/url';

import { NotificationEnum } from 'types/notification';
import { ROUTE } from 'types/route';

import { useRoute } from './useRoute';

/**
 * UnauthorizedException (401): JWT token is missing, invalid, or unauthorized.
 * BadRequestException (400): the request’s input is invalid.
 * NotFoundException (404): appointmentId doesn’t exist.
 * ThrottledException (429): this request is throttled because this client made too many requests.
 * @param status
 */
function getNotification(status?: HttpStatusCode) {
    switch (status) {
        case HttpStatusCode.OK:
            return 'pages_appointment_notification_success_rescheduling';
        case HttpStatusCode.TOO_MANY_REQUESTS:
            return 'pages_appointment_too_many_requests';
        case HttpStatusCode.UNAUTHORIZED:
            return 'pages_appointment_token_expired';
        case HttpStatusCode.NOT_FOUND:
        case HttpStatusCode.BAD_REQUEST:
            return 'pages_appointment_not_exist';
        default:
            return 'calendar_general_error';
    }
}

/**
 * This hook returns a shared method for CalendarPage and MobileCalendarPage,
 * Which invoke an appointment update and add appropriate notifications
 */
export function useUpdateAppointment(): () => Promise<void> {
    const { t } = useTranslation();
    const notification = useNotificationQueue();
    const appointmentId = getAppointmentId(location.search);
    const { startTime , endTime } = useAppSelector(state => state.calendar);
    const { changeRoute } = useRoute();

    return async () => {
        if (appointmentId && startTime && endTime) {
            const response = await updateAppointment(appointmentId, { startTime, endTime, timezone: getCurrentIANATimeZone() });
            notification.removeAll();
            if (response.status === HttpStatusCode.OK) {
                changeRoute(ROUTE.APPOINTMENT_CONFIRMATION, { id: appointmentId });
                //a workaround to show notification on second rendering
                setTimeout(() => {
                    notification.add({
                        type: 'success',
                        message: t(getNotification(response.status)),
                        isClosed: false,
                    }, NotificationEnum.UpdateAppointmentNotification);
                }, 1000);
                return;
            }
            notification.add({
                type: 'error',
                message: t(getNotification(response.status)),
                isClosed: true,
            }, NotificationEnum.UpdateAppointmentNotification);
        }
    };
}
