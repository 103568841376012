import { ComponentProps, FC } from 'react';
import { createPortal } from 'react-dom';

import { Footer } from './Footer';

type Props = {
    container: HTMLElement,
}

export const FooterPortal: FC<Props & ComponentProps<typeof Footer>> = ({
    container,
    children,
    ...rest
}) => {
    return (
        createPortal(<Footer {...rest}>{children}</Footer>, container)
    );
};
