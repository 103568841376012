import { useEffect, useMemo, useState } from 'react';

import { ConsentManager } from 'utils/consentManager';
import { onConsentBannerChange, onContentBannerRemove } from 'utils/layout';

import { LanguageCode } from 'types/language';


/**
 * This hook creates the Cookie Consent banner and adds a listener to the "Accept" button
 * If the "Accept" button has been clicked, it returns true
 * Otherwise, false
 * @param locale
 */
export const useConsentManager = (locale: LanguageCode) => {
    const [ isTrackingAccepted, setIsTrackingAccepted ] = useState(false);

    const consentManager = useMemo(() => new ConsentManager(
        onConsentBannerChange,
        onContentBannerRemove,
    ), []);

    useEffect(() => {
        consentManager.listenBannerRender();
        consentManager.addScript(locale);
        consentManager.listenClickAccept(() => setIsTrackingAccepted(true));
        return () => {
            consentManager.onDeleteConsentManager();
        };
    }, [locale, setIsTrackingAccepted]);

    return isTrackingAccepted;
};
