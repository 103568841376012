import { FC, HTMLAttributes } from 'react';

import { block } from 'utils/block';

import './Title.scss';

type Props = {
    title: string;
    subtitle?: string;
}

const b = block('title');

export const Title: FC<Props & HTMLAttributes<HTMLElement>> = ({
    title,
    subtitle,
    className = '',
    ...rest
}) => {
    return (
        <header className={b().mix(className)} {...rest}>
            <h1 className={b('h1')}>{title}</h1>
            {subtitle ??
                <h2 className={b('h2')}>{subtitle}</h2>
            }
        </header>
    );
};
