import { FC, HTMLAttributes, ReactNode, useEffect, useRef } from 'react';

import { block } from 'utils/block';
import { onFooterChange } from 'utils/layout';

import './Footer.scss';

const b = block('footer');

type Props = {
    children: ReactNode;
}

export const Footer: FC<Props & HTMLAttributes<HTMLElement>> = ({
    children,
    className = '',
    ...rest
}) => {
    const ref = useRef<HTMLElement>(null);

    useEffect(() => {
        const htmlElement = ref.current!;
        onFooterChange();
        htmlElement.addEventListener('resize', onFooterChange);
        return () => {
            htmlElement.removeEventListener('resize', onFooterChange);
        };
    }, []);

    return (
        <footer className={b().mix(className)} ref={ref} {...rest}>
            {children}
        </footer>
    );
};
