import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@amzn/ring-safe-web';
import { useAppointment } from 'hooks/useAppointment';
import { useCreateAppointment } from 'hooks/useCreateAppointment';
import { useUpdateAppointment } from 'hooks/useUpdateAppointment';
import { ReactComponent as CalendarSetUp } from 'static/images/calendar-set-up.svg';

import { Calendar } from 'components/Calendar';
import { CalendarNewDeviceOTPModal } from 'components/CalendarNewDeviceOTPModal';
import { FooterPortal } from 'components/Footer';
import { PageBody, PageHeader } from 'components/Page';
import { block } from 'utils/block';
import { getRootContainer } from 'utils/layout';
import { useAppSelector } from 'utils/store';
import { getAppointmentId } from 'utils/url';

import './CalendarPage.scss';

const b = block('calendar-page');

const NUMBER_OF_WEEKS = 2;
const COUNT_OF_SLOTS = 28;

export const CalendarPage: FC = () => {
    const { t } = useTranslation();

    const appointment = useAppointment();
    const onCreateAppointment = useCreateAppointment();
    const onUpdateAppointment = useUpdateAppointment();
    const appointmentId = getAppointmentId(location.search);
    const hasSource = useAppSelector(state => Boolean(state.calendar.source));
    const { startTime , endTime } = useAppSelector(state => state.calendar);

    const [isRequestSending, setIsRequestSending] = useState(false);

    const onButtonClick = async () => {
        setIsRequestSending(true);
        if (appointmentId) {
            await onUpdateAppointment();
        } else {
            await onCreateAppointment();
        }
        setIsRequestSending(false);
    };

    const getIsDisable = () => {
        if (!hasSource) {
            return true;
        }
        return (
            //if it's a new user than appointment should be created
            !appointment &&
            //if it's the user with existing appointment, then appointmentId should be in search url parameters
            !(appointmentId && startTime && endTime) ||
            //if any request were sent then button should be disabled
            isRequestSending
        );
    };

    return (
        <PageBody className={b()}>
            <CalendarNewDeviceOTPModal />
            <PageHeader
                title={t('pages_appointment_booking_title')}
                subtitle={t('pages_appointment_booking_subtitle')}
            />
            <Calendar
                id='calendar-page-calendar'
                numOfWeeksToShow={NUMBER_OF_WEEKS}
                countOfSlots={COUNT_OF_SLOTS}
                className={b('calendar')}
            />
            <CalendarSetUp
                className={b('image')}
                aria-hidden
            />
            <FooterPortal container={getRootContainer()}>
                <Button
                    color='primary'
                    size='m'
                    variant='filled'
                    onClick={onButtonClick}
                    id="calendar-page-redirect-button"
                    disabled={getIsDisable()}
                >
                    {t('pages_appointment_booking_button')}
                </Button>
            </FooterPortal>
        </PageBody>
    );
};
