import { HeapEventName, HeapRewriteProps } from 'types/heap';

/**
 * This function is a wrapper, that checks that window.heap was loaded and invokes track API
 * track(event, property) is used to send custom events and associated metadata to Heap that are not automatically capture
 * documentation: https://developers.heap.io/reference/track
 */
export function track(event: HeapEventName, properties?: Object) {
    if (!window.heap || !window.heap.loaded) {
        return;
    }
    window.heap.track(event, properties);
}

/**
 * This function obfuscates sensible data:
 * As heap captures a tracking event, the event's data will be processed by this function
 * It hides all personal sensible data and prevent sending it to the remote dashboards
 *
 * Example:    "/steps/appointment-booking?id=1648111629000"
 * Obfuscated: "/steps/appointment-booking?id=REDACTED"
 *
 * Taken from: https://code.amazon.com/packages/RingLoopWebServer/blobs/mainline/--/src/react/ssb/public/script/heap.js
 */
export function rewrite(props: HeapRewriteProps) {

    var queryParamsRegex = new RegExp('([&?]id=)([A-Za-z0-9_@.-]+?)(?=/|$|&)', 'g');

    var redactText = '$1REDACTED';

    //Event Properties
    if (props.event_properties) {
        if (props.event_properties.href) {
            props.event_properties.href = props.event_properties.href.replace(queryParamsRegex, redactText);
        }
        if (props.event_properties.hierarchy && props.event_properties.hierarchy.indexOf('[href') > -1) {
            props.event_properties.hierarchy = props.event_properties.hierarchy.replace(queryParamsRegex, redactText);
        }
    }

    // PageView Properties
    if (props.pageview_properties && props.pageview_properties.query) {
        props.pageview_properties.query = props.pageview_properties.query.replace(queryParamsRegex, redactText);
    }

    // Session Properties
    if (props.session_properties) {
        if (props.session_properties.referrer) {
            props.session_properties.referrer = props.session_properties.referrer.replace(queryParamsRegex, redactText);
        }
        if (props.session_properties.query) {
            props.session_properties.query = props.session_properties.query.replace(queryParamsRegex, redactText);
        }
    }
    return props;
}
