import { ComponentProps,FC } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from '@amzn/ring-safe-web';

type Props = {
    container?: HTMLElement,
}

export const ModalPortal: FC<ComponentProps<typeof Modal> & Props> = ({
    container = document.getElementById('root')!,
    children,
    ...props
}) => {
    return createPortal(<Modal {...props}>{children}</Modal>, container);
};
