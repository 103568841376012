import { useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { TenantId } from '@amzn/ring-rse-scheduling-service-typescript-client';
import { getTenantConfig } from 'api/tenants';
import { useInitialRedirect } from 'hooks/useInitialRedirect';
import { useInitLanguageSelector } from 'hooks/useInitLanguageSelector';
import { useLanguage } from 'hooks/useLanguage';
import { useThirdPartyAnalytics } from 'hooks/useThirdPartyAnalytics';
import { useView } from 'hooks/useView';
import { AccountVerificationPage } from 'pages/AccountVerificationPage';
import { AppointmentPage } from 'pages/AppointmentPage';
import { CalendarPage } from 'pages/CalendarPage';
import { ContactPage } from 'pages/ContactPage';
import { ErrorPage } from 'pages/ErrorPage';
import { MobileAppointmentPage } from 'pages/MobileAppointmentPage';
import { MobileCalendarPage } from 'pages/MobileCalendarPage';
import { WelcomePage } from 'pages/WelcomePage';

import { DisplayLocationContext } from 'content/DisplayLocation';
import { block } from 'utils/block';
import { getRootContainer } from 'utils/layout';
import { useAppDispatch } from 'utils/store';

import { View } from 'types/common';
import { ROUTE } from 'types/route';

import { setTenantConfig } from './AppSlice';
import { AppSteps } from './AppSteps';

const b = block('app');

const PATH_PREFIX = '/:tenant?/:lang?';

export const App = () => {
    const location = useLocation();
    const { displayLocation, setDisplayLocation } = useContext(DisplayLocationContext);
    const [transitionStage, setTransitionStage] = useState<'out' | 'in' | false>(false);
    const view = useView();
    const dispatch = useAppDispatch();
    const { language } = useLanguage();

    const initTenantConfig = async () => {
        const tenantConfig = await getTenantConfig(TenantId.Intercom);
        dispatch(setTenantConfig(tenantConfig));
    };

    useInitialRedirect();
    useThirdPartyAnalytics(language);
    useInitLanguageSelector();

    useEffect(() => {
        initTenantConfig();
    }, []);

    useEffect(() => {
        if (location !== displayLocation) {
            setTransitionStage('out');
        }
    }, [location, displayLocation]);

    const onAnimationEnd = () => {
        if (transitionStage === 'out') {
            setTransitionStage('in');
            setDisplayLocation(location);
            getRootContainer().scrollTo(0, 0);
        }
    };

    if (displayLocation === null) {
        return <div className={b({ fade: transitionStage })} onAnimationEnd={onAnimationEnd}/>;
    }

    return (
        <Routes location={displayLocation}>
            <Route
                path={PATH_PREFIX}
                element={<WelcomePage className={b({ fade: transitionStage })} onAnimationEnd={onAnimationEnd}/>}
            />
            <Route
                element={
                    <AppSteps
                        className={b({ fade: transitionStage })}
                        onAnimationEnd={onAnimationEnd}
                    />
                }
            >
                <Route path={`${PATH_PREFIX}${ROUTE.CONTACT_INFORMATION}`} element={<ContactPage />} />
                <Route path={`${PATH_PREFIX}${ROUTE.ACCOUNT_VERIFICATION}`} element={<AccountVerificationPage />} />
                {/**
                  * not used for Intercom
                  * <Route path={`${PATH_PREFIX}${ROUTE.INTERESTS}`} element={<InterestsPage/>}/>
                  **/}
                <Route
                    path={`${PATH_PREFIX}${ROUTE.APPOINTMENT_BOOKING}/*`}
                    element={view === View.mobile ? <MobileCalendarPage /> : <CalendarPage />}
                />
            </Route>
            <Route path={`${PATH_PREFIX}${ROUTE.APPOINTMENT_CONFIRMATION}/*`}
                element={
                    view === View.laptop ?
                        <AppointmentPage
                            className={b({ fade: transitionStage })}
                            onAnimationEnd={onAnimationEnd}
                        /> :
                        <MobileAppointmentPage
                            className={b({ fade: transitionStage })}
                            onAnimationEnd={onAnimationEnd}
                        />
                }
            />
            <Route
                path="*"
                element={<ErrorPage className={b({ fade: transitionStage })} onAnimationEnd={onAnimationEnd}/>}
            />
        </Routes>
    );
};
