import { Action, createSlice, PayloadAction, SliceCaseReducers, ThunkAction } from '@reduxjs/toolkit';
import { getCalendarSource } from 'api/calendar';

import { DEFAULT_RETRY_COUNT } from 'constants/common';
import { asyncRetry } from 'utils/common';
import { getTimeResourceId } from 'utils/tenant';

import { LanguageCode } from 'types/language';
import { CalendarType, StateType } from 'types/state';

export const initialState: CalendarType = {
    startTime: undefined,
    endTime: undefined,
    source: undefined,
    isNoSlots: false,
};

export const calendarSlice = createSlice<CalendarType, SliceCaseReducers<CalendarType>, string>({
    name: 'calendar',
    initialState,
    reducers: {
        setSlotTime: (state, action: PayloadAction<Pick<CalendarType, 'startTime' | 'endTime'>>) => {
            return {
                ...state,
                startTime: action.payload.startTime,
                endTime: action.payload.endTime,
            };
        },
        setCalendarSource: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                source: action.payload,
                isNoSlots: action.payload?.search(/\S/) === -1 || false,
            };
        },
        setIsNoSlots: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isNoSlots: action.payload,
            };
        },
    },
});

export function getCalendarSourceThunk(language: LanguageCode): ThunkAction<Promise<void>, StateType, void, Action> {
    return async (dispatch, getState) => {
        const services = getState().tenantConfig?.services;
        if (!services) {
            return;
        }
        const source = await asyncRetry(getCalendarSource, DEFAULT_RETRY_COUNT)(language, getTimeResourceId(services, language));
        dispatch(setCalendarSource(source));
    };
}

export const {
    setSlotTime,
    setCalendarSource,
    setIsNoSlots,
} = calendarSlice.actions;
export const calendarReducer = calendarSlice.reducer;
