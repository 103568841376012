import { FC } from 'react';
import { CreateAppointmentV2ResponseContent } from '@amzn/ring-rse-scheduling-service-typescript-client';

import { AppointmentMobile, AppointmentMobileSkeleton } from 'components/AppointmentMobile';
import { block } from 'utils/block';

const b = block('appointment-page');

type Props = {
    currentAppointmentId?: string;
    appointments?: CreateAppointmentV2ResponseContent[];
    onCancel: (appointmentId: string) => () => void;
}

export const MobileAppointmentPageList: FC<Props> = ({
    currentAppointmentId,
    appointments,
    onCancel,
}) => {
    if (!currentAppointmentId || !appointments) {
        return <AppointmentMobileSkeleton />;
    }
    return (
        <>
            {appointments?.map(({ appointmentId, startTime }) => (
                <AppointmentMobile
                    appointmentId={appointmentId}
                    startTime={startTime}
                    onCancel={onCancel(appointmentId)}
                    className={b('appointment')}
                    key={appointmentId}
                />
            ))}
        </>
    );
};
