import {
    CreateAppointmentV2RequestContent,
    GetAppointmentV2ResponseContent,
    UpdateAppointmentV2RequestContent,
} from '@amzn/ring-rse-scheduling-service-typescript-client';
import { client } from 'api/client';

import { HttpStatusCode } from 'constants/httpCodes';
import { asyncRetry } from 'utils/common';
import { clearHasAppointment, clearIsAuthorized, setHasAppointment } from 'utils/cookies';
import { getAppointmentId, getResponseData } from 'utils/url';

export async function createAppointment(appointment: CreateAppointmentV2RequestContent) {
    const response = await getResponseData(client.createAppointmentV2(appointment));
    if (response.status === HttpStatusCode.OK) {
        setHasAppointment(response.data.endTime);
    }
    if (response.status === HttpStatusCode.UNAUTHORIZED) {
        clearHasAppointment();
        clearIsAuthorized();
    }
    return response;
}

export async function updateAppointment(appointmentId: string, appointment: Required<UpdateAppointmentV2RequestContent>) {
    const response = await getResponseData(client.updateAppointmentV2(appointmentId, appointment));
    if (response.status === HttpStatusCode.OK) {
        setHasAppointment(appointment.endTime!);
    }
    if (response.status === HttpStatusCode.UNAUTHORIZED) {
        clearHasAppointment();
        clearIsAuthorized();
    }
    return response;
}

export async function getAppointment(id: string) {
    const response = await getResponseData(client.getAppointmentV2(id));
    switch (response.status) {
        case HttpStatusCode.OK: {
            setHasAppointment(response.data.endTime);
            break;
        }
        case HttpStatusCode.UNAUTHORIZED: {
            clearHasAppointment();
            clearIsAuthorized();
            break;
        }
        default: {
            clearHasAppointment();
        }
    }
    return response;
}

export async function getAppointments() {
    const response = await getResponseData(client.getAllAppointmentsV2());
    if (response.status === HttpStatusCode.UNAUTHORIZED) {
        clearHasAppointment();
        clearIsAuthorized();
    }
    return response;
}

export async function cancelAppointment(id: string) {
    const response = await getResponseData(client.cancelAppointmentV2(id));
    if (response.status === HttpStatusCode.OK) {
        clearHasAppointment();
    }
    return response;
}

export async function getAppointmentFromURL(retryCount: number): Promise<GetAppointmentV2ResponseContent | null> {
    const appointmentId = getAppointmentId(location.search);
    if (appointmentId) {
        const response = await asyncRetry(getAppointment, retryCount)(appointmentId);
        const appointment = response.data;
        if (response.status === HttpStatusCode.OK && appointment) {
            return appointment;
        }
    }
    return null;
}
