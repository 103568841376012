import { useEffect } from 'react';
import { useConsentManager } from 'hooks/useConsentManager';
import { useHeap } from 'hooks/useHeap';

import { getPrivacyCookie } from 'utils/cookies';

import { PrivacyCookieValue } from 'types/cookies';
import { LanguageCode } from 'types/language';

/**
 * This hook loads third party analytics tools:
 * 1. Heap
 * @param locale
 */
export const useThirdPartyAnalytics = (locale: LanguageCode) => {
    const isTrackingAccepted = useConsentManager(locale);
    const isHeapAllowed = getPrivacyCookie(PrivacyCookieValue.heap);

    const { load, addAuthEventProperties } = useHeap();

    useEffect(() => {
        // Heap will be enabled when one of the following use cases occurs:
        if (isHeapAllowed || isTrackingAccepted) {
            load();
        }
    }, [
        isHeapAllowed,
        isTrackingAccepted,
        load,
        addAuthEventProperties,
    ]);
};
