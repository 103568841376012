import { ComponentProps, FC } from 'react';
import { Checkbox } from '@amzn/ring-safe-web';
import { ReactComponent as CheckCircle } from 'static/icons/check-circle.svg';

import { block } from 'utils/block';
import { noop } from 'utils/common';

const b = block('appointment');

export const AppointmentSkeleton: FC<Partial<ComponentProps<typeof Checkbox>>> = ({
    className = '',
    id = 'appointment-checkbox-skeleton',
    ...rest
}) => {
    return (
        <Checkbox
            checked={false}
            position='right'
            withInputCheckbox={false}
            onClick={noop}
            id={id}
            className={b({ loading: true }).mix(className)}
            icon={<CheckCircle aria-hidden />}
            aria-hidden={true}
            tabIndex={-1}
            {...rest}
        >
            <div className={b('content')}>
                <p className={b('title')} />
                <p className={b('subtitle')} />
            </div>
        </Checkbox>
    );
};
