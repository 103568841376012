import { AsyncFunction } from 'types/common';

/**
 * This function checks if 'something' is string;
 * @param something
 */
export function isString<T>(something: T | string): something is string {
    return typeof something === 'string';
}

/**
 * This function is a function for sort method
 * Sort 2 strings in ascending order
 * @param string1
 * @param string2
 */
export function ascendingStringSorter(string1: string, string2: string) {
    if  (string1 > string2) {
        return 1;
    }
    if (string1 < string2) {
        return -1;
    }
    return 0;
}

/**
 * This function is a compare function for sort method
 * Sort 2 strings in descending order
 * @param string1
 * @param string2
 */
export function descendingStringSorter(string1: string, string2: string) {
    if  (string1 > string2) {
        return -1;
    }
    if (string1 < string2) {
        return 1;
    }
    return 0;
}

/**
 * This function returns all possible subsets without permutations
 * @param array
 */
export function getAllSubsets<T>(array: T[]) {
    return array.reduce((subsets, item) => subsets.concat(
        subsets.map(set => [item, ...set]),
    ), [[]] as T[][]);
}

/**
 * This function returns true, if the given arrays contains the array
 * @param array
 * @param arrays
 * @param compareFn
 */
export function isArrayInArrays<T>(array: T[], arrays: T[][], compareFn = (a: T, b: T) => (a === b)) {
    return arrays.some(arr => {
        let isDuplicate = true;
        for (let i = 0; i < array.length; i++) {
            isDuplicate &&= compareFn(arr[i], array[i]);
        }
        return isDuplicate;
    });
}

/**
 * This function returns all possible permutations for a given array
 * @param array
 * @param compareFn - in case T is a reference type, you must provide a function to detect duplicates
 */
export function getPermutations<T>(
    array: T[],
    compareFn = (a: T, b: T) => (a === b),
): T[][] {
    if (array.length === 0) {
        return [[]];
    }
    const permutations: T[][] = [];
    const slice = array.slice(1);
    const hasDuplicates = new Set(slice).size !== slice.length;
    const subPermutations = getPermutations(slice, compareFn);
    for (let i = 0; i < subPermutations.length; i++) {
        for (let j = 0; j < array.length; j++) {
            const permutation = [...subPermutations[i]];
            permutation.splice(j, 0, array[0]);
            if (hasDuplicates && isArrayInArrays(permutation, permutations, compareFn)) {
                continue;
            }
            permutations.push(permutation);
        }
    }
    return permutations;
}

/**
 * This function returns a wrapper function over a passed asynchronous function f, which may return an error.
 * The wrapper calls f, if the call returns an error then it invokes f one more time.
 * But no more than "count" times.
 * @param f
 * @param count
 */
export const asyncRetry = <A extends unknown[], R>(f: AsyncFunction<A, R>, count: number): AsyncFunction<A, R> => {
    let currentCount = count;
    const retryInner = async (...args: A): Promise<R> => {
        try {
            return await f(...args);
        } catch (e) {
            currentCount--;
            if (currentCount) {
                return retryInner(...args);
            }
            throw e;
        }
    };
    return retryInner;
};

/**
 * This is a function, which performs no operation and once invoked it returns undefined.
 */
export function noop() {
    return undefined;
}

export function asyncTimeout<T>(fn: () => T, ms: number): Promise<T> {
    return new Promise((res) => {
        setTimeout(() => res(fn()), ms);
    });
}

/**
 * This function transforms a given object into a string,
 * using ';' as a separator and '=' to match the keys and the values
 * @param obj
 */
export function objToString(obj: Record<string, string | number | boolean | undefined | null | Object>): string {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (!value) {
            return acc;
        }
        if (typeof value === 'boolean') {
            return `${acc}${key};`;
        }
        return `${acc}${key}=${value.toString()};`;
    }, '');
}

/**
 * This function makes the first letter of a string uppercase
 * @param str
 */
export function capitalizeFirstLetter(str: string) {
    return `${str[0].toUpperCase()}${str.slice(1)}`;
}

/**
 * This function adds zeros to the beginning of the positive number until its length equals 2
 * @param number
 */
export function addZerosAtStart(number: number): string {
    return number.toString().padStart(2, '0');
}
