import { FC, HTMLAttributes, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sendOTPCode } from 'api/verification';
import { useLanguage } from 'hooks/useLanguage';
import { receiveChangeIdThunk } from 'pages/AccountVerificationPage/AccountVerificationPageSlice';
import { ReactComponent as DoorBox } from 'static/icons/doorbox.svg';

import { ModalPortal } from 'components/ModalPortal';
import { NewDeviceOTPForm } from 'components/NewDeviceOTPForm';
import { SMSVerificationForm } from 'components/SMSVerificationForm';
import { DEFAULT_RETRY_COUNT } from 'constants/common';
import { block } from 'utils/block';
import { asyncRetry } from 'utils/common';
import { getRootContainer } from 'utils/layout';
import { formatPhoneNumberForBackend, getFullPhoneNumber } from 'utils/phone';
import { useAppDispatch, useAppSelector } from 'utils/store';

import { PhoneType } from 'types/common';

import './NewDeviceOTPModal.scss';


const b = block('new-device-otp-modal');

type Props = {
    isOpen: boolean;
    onSubmitOTP: Function;
}

export const NewDeviceOTPModal: FC<Props & HTMLAttributes<HTMLDialogElement>> = ({
    isOpen,
    onSubmitOTP,
    className = '',
    id = 'new-device-otp-form',
    ...rest
}) => {
    const { t } = useTranslation();
    const [phone, setPhone] = useState<PhoneType | undefined>();
    const dispatch = useAppDispatch();
    const code = useAppSelector((state) => state.smsVerificationForm.code.value!);
    const challengeId = useAppSelector((state) => state.challengeId!);
    const stringPhone = phone ? formatPhoneNumberForBackend(phone) : '';

    const { language } = useLanguage();

    const onButtonClick = (phone: PhoneType) => {
        setPhone(phone);
    };

    const onChangePhoneLinkClick = () => {
        setPhone(undefined);
    };

    const sendSMS = useCallback(() => dispatch(receiveChangeIdThunk(stringPhone!, language, DEFAULT_RETRY_COUNT)), [stringPhone]);

    const onSubmit = async () => {
        const status = await asyncRetry(sendOTPCode, DEFAULT_RETRY_COUNT)(code, challengeId, stringPhone);
        onSubmitOTP(status);
        return status;
    };

    return (
        <ModalPortal
            title={t('sms_verification_form_title')}
            className={b().mix(className)}
            icon={
                <DoorBox
                    className={b('icon')}
                    aria-hidden
                />
            }
            isOpen={isOpen}
            id={id}
            contentClassName={b('content')}
            container={getRootContainer()}
            {...rest}
        >
            {phone ? (
                <SMSVerificationForm
                    phone={getFullPhoneNumber(phone)}
                    id={`${id}-sms-form`}
                    onChangePhoneLinkClick={onChangePhoneLinkClick}
                    onSubmit={onSubmit}
                    sendSMS={sendSMS}
                    className={b('sms-form')}
                />
            ) : (
                <NewDeviceOTPForm
                    onButtonClick={onButtonClick}
                    id={`${id}-phone-form`}
                    tabIndex={isOpen ? 0 : -1}
                />
            )}
        </ModalPortal>
    );
};
