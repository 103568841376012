import { FC, HTMLAttributes } from 'react';
import { DropdownItem, DropdownList } from '@amzn/ring-safe-web';

import { LANGUAGES } from 'constants/languages';
import { block } from 'utils/block';

import { LanguageCode } from 'types/language';

const b = block('language-selector');

type Props = {
    onCheck: (lang: LanguageCode) => () => void;
    selectedLanguage?: LanguageCode;
    languageCodes: LanguageCode[];
    isOpen: boolean;
    id: string;
}

export const LanguageSelectorList: FC<Props & HTMLAttributes<HTMLElement>> = ({
    onCheck,
    isOpen,
    className = '',
    languageCodes,
    selectedLanguage,
    id,
    ...rest
}) => {
    return (
        <DropdownList
            isOpen={isOpen}
            className={b('list').mix(className)}
            {...rest}
        >
            {languageCodes.filter(lang => LANGUAGES[lang]).map(lang => (
                <DropdownItem
                    onClick={onCheck(lang)}
                    icon={<span className={b('list-icon').mix(`fi fi-${lang.split('-')[1].toLowerCase()}`)} />}
                    isChecked={selectedLanguage === lang}
                    key={lang}
                    id={`${id}-${lang}`}
                    className={b('list-item', { checked: selectedLanguage === lang })}
                >
                    <div className={b('item-label')}>{LANGUAGES[lang].nameLocalShort}</div>
                </DropdownItem>
            ))}
        </DropdownList>
    );
};
