import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { CalloutCard } from '@amzn/ring-safe-web';

import { block } from 'utils/block';
import { useAppSelector } from 'utils/store';

const b = block('sms-verification-form');

export const SMSVerificationFormNotification: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
    const { t } = useTranslation();
    const message = useAppSelector(({ smsVerificationForm }) => smsVerificationForm.notification);

    if (!message) {
        return null;
    }

    return (
        <CalloutCard title={t(message.title)} status='error' className={b('notification')} {...props}>
            {t(message.description)}
        </CalloutCard>
    );
};
