import { FC, HTMLAttributes, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { GetAppointmentV2ResponseContent } from '@amzn/ring-rse-scheduling-service-typescript-client';
import { Button, Link, NotificationList, useNotificationQueue } from '@amzn/ring-safe-web';
import { getAppointment, getAppointments } from 'api/appointment';
import { useHeap } from 'hooks/useHeap';
import { phone } from 'phone';
import { ReactComponent as Calendar } from 'static/images/calendar.svg';

import { AddToCalendar } from 'components/AddToCalendar';
import { AppointmentCard } from 'components/AppointmentCard';
import { CancelAppointmentModal } from 'components/CancelAppointmentModal';
import { FooterPortal } from 'components/Footer';
import { Header } from 'components/Header';
import { NewDeviceOTPModal } from 'components/NewDeviceOTPModal';
import { Page } from 'components/Page';
import { RescheduleAppointmentModal } from 'components/RescheduleAppointmentModal';
import { DEFAULT_RETRY_COUNT, SUPPORTED_CALENDAR_TYPES } from 'constants/common';
import { HttpStatusCode } from 'constants/httpCodes';
import { block } from 'utils/block';
import { asyncRetry } from 'utils/common';
import { getCookie } from 'utils/cookies';
import { getRootContainer } from 'utils/layout';
import { formatPhoneNumber } from 'utils/phone';
import { createRoute, getAppointmentId } from 'utils/url';

import { View } from 'types/common';
import { Cookies } from 'types/cookies';
import { HeapEventName } from 'types/heap';
import { NotificationEnum } from 'types/notification';
import { ROUTE } from 'types/route';

import { AppointmentPageList } from './AppointmentPageList';

import './AppointmentPage.scss';

const b = block('appointment-page');

export const AppointmentPage: FC<HTMLAttributes<HTMLDivElement>> = ({
    className = '',
    ...rest
}) => {
    const { t } = useTranslation();
    const [appointments, setAppointments] = useState<GetAppointmentV2ResponseContent[]>();
    const [currentAppointment, setCurrentAppointment] = useState<GetAppointmentV2ResponseContent>();
    const notifications = useNotificationQueue();
    const location = useLocation();

    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
    const [isNewDeviceOTPModalOpen, setIsNewDeviceOTPModalOpen] = useState(false);
    const { track } = useHeap();

    const onSubmitOTP = async (code: HttpStatusCode) => {
        if (code === HttpStatusCode.OK) {
            setIsNewDeviceOTPModalOpen(false);
            notifications.removeAll();
            receiveAppointments();
        }
    };

    const receiveAppointments  = async () => {
        const appointmentId = getAppointmentId(location.search);
        if (appointmentId) {
            const { data, status } = await asyncRetry(getAppointment, DEFAULT_RETRY_COUNT)(appointmentId);
            if (status === HttpStatusCode.OK && data) {
                setAppointments([data]);
                setCurrentAppointment(data);
                return;
            }
        }
        const { data, status } = await asyncRetry(getAppointments, DEFAULT_RETRY_COUNT)();
        if (status === HttpStatusCode.OK && data.appointments) {
            setAppointments(data.appointments);
            if (data.appointments.length == 0) {
                notifications.add({
                    type: 'error',
                    message:
                        <p>
                            {t(NotificationEnum.FailedReceivingOfAppointments) }
                            <Link
                                size='s'
                                color='negative'
                                link={createRoute(ROUTE.CONTACT_INFORMATION)}
                                type='internal'
                                id='appointment-page-notification-create-appointment-link'
                            >
                                {t('create_new_appointment')}
                            </Link>
                        </p>,
                    isClosed: true,
                }, NotificationEnum.FailedReceivingOfAppointments);
            } else {
                setCurrentAppointment(data.appointments[0]);
                if (appointmentId) {
                    notifications.add({
                        type: 'error',
                        message: t(NotificationEnum.AppointmentWithWrongId),
                        isClosed: true,
                    }, NotificationEnum.AppointmentWithWrongId);
                }
            }
            return;
        }
        if (!getCookie((Cookies.isAuthorized))) {
            setIsNewDeviceOTPModalOpen(true);
            track(HeapEventName.NewDeviceModalOpen);
            return;
        }
    };

    useEffect(() => {
        receiveAppointments();
    }, []);

    useEffect(() => {
        notifications.entries.forEach(({ id }) => {
            if (id !== NotificationEnum.AppointmentSuccessCancellation) {
                notifications.remove(id);
            }
        });
    }, [location.pathname]);

    const onButtonClick = () => {
        window.location.href = 'https://ring.com';
    };


    return (
        <div className={b().mix(className)} {...rest}>
            <NewDeviceOTPModal
                isOpen={isNewDeviceOTPModalOpen}
                onSubmitOTP={onSubmitOTP}
            />
            <CancelAppointmentModal
                isOpen={isCancelModalOpen}
                id='appointment-page-cancel-modal'
                appointmentId={currentAppointment?.appointmentId!}
                onCancel={() => setIsCancelModalOpen(false)}
            />
            <RescheduleAppointmentModal
                isOpen={isRescheduleModalOpen}
                id='appointment-page-reschedule-modal'
                appointmentId={currentAppointment?.appointmentId!}
                onCancel={() => setIsRescheduleModalOpen(false)}
            />
            <Header header={t('pages_appointment_header')} className={b('header')} />
            <Page className={b('page')}>
                <article className={b('left-block')}>
                    <h1 className={b('title')}>
                        {t('pages_appointment_title',
                            { phone: currentAppointment?.targetPhoneNumber ?
                                formatPhoneNumber(
                                    currentAppointment.targetPhoneNumber,
                                    phone(currentAppointment.targetPhoneNumber).countryCode!,
                                ) :
                                t('pages_appointment_title_phone_stub'),
                            })}
                    </h1>
                    <section className={b('appointments')}>
                        <h2 className={b('subtitle')}>{t('pages_appointment_subtitle')}</h2>
                        <AppointmentPageList
                            currentAppointmentId={currentAppointment?.appointmentId}
                            appointments={appointments}
                            setCurrentAppointment={setCurrentAppointment}
                        />
                        {currentAppointment &&
                            <AddToCalendar
                                title={t('add_to_calendar_title')}
                                supportedTypes={SUPPORTED_CALENDAR_TYPES}
                                id={'appointment-page-add-to-calendar'}
                                event={{
                                    startTime: currentAppointment.startTime,
                                    endTime: currentAppointment.endTime,
                                    timezone: currentAppointment.timezone,
                                    title: t('add_to_calendar_event_title'),
                                    description: t('add_to_calendar_event_description'),
                                }}
                                view={View.laptop}
                                isAbsolute={false}
                            />
                        }
                    </section>
                </article>
                <article className={b('right-block')}>
                    <NotificationList className={b('notifications')} />
                    <Calendar
                        className={b('illustration')}
                        aria-hidden
                    />
                    <AppointmentCard
                        startTime={currentAppointment?.startTime}
                        onCancelClick={() => setIsCancelModalOpen(true)}
                        onRescheduleClick={() => setIsRescheduleModalOpen(true)}
                        className={b('card')}
                        id={'appointment-page-card'}
                    />
                </article>
                <FooterPortal container={getRootContainer()}>
                    <Button
                        color='primary'
                        size='m'
                        variant='filled'
                        onClick={onButtonClick}
                        id="appointment-page-redirect-button"
                        disabled={false}
                        className={b('button', { type: 'redirect' })}
                    >
                        {t('pages_appointment_button')}
                    </Button>
                </FooterPortal>
            </Page>
        </div>
    );
};
