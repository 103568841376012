import { FC, HTMLAttributes } from 'react';
import { ReactComponent as AppleCalendar } from 'static/icons/apple-calendar.svg';
import { ReactComponent as IcalCalendar } from 'static/icons/calendar.svg';
import { ReactComponent as GoogleCalendar } from 'static/icons/google-calendar.svg';
import { ReactComponent as Office365Calendar } from 'static/icons/microsoft-office-calendar.svg';
import { ReactComponent as OutlookCalendar } from 'static/icons/outlook-calendar.svg';
import { ReactComponent as YahooCalendar } from 'static/icons/yahoo-calendar.svg';

import { CalendarType } from 'types/calendar';

type Props = {
    calendarType: CalendarType
}

export const AddToCalendarIcon: FC<Props & HTMLAttributes<SVGElement>> = ({
    calendarType,
    ...props
}) => {
    switch (calendarType) {
        case CalendarType.GOOGLE: return <GoogleCalendar {...props}/>;
        case CalendarType.YAHOO: return <YahooCalendar {...props}/>;
        case CalendarType.ICAL: return <IcalCalendar fill='#1998d5' {...props} />;
        case CalendarType.OUTLOOK: return <OutlookCalendar {...props}/>;
        case CalendarType.APPLE: return <AppleCalendar {...props}/>;
        case CalendarType.OFFICE_365: return <Office365Calendar {...props}/>;
        default: return null;
    }
};
