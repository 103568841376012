import moment from 'moment-timezone';

import { currentStage, STAGE } from 'constants/stage';

import { LanguageCode } from 'types/language';

/**
 * This function takes a row html markup, provided by VAS team
 * For more information: https://quip-amazon.com/CxshAgg60UnX/Integration-of-VAS-Calendar-widget
 */
export async function getCalendarSource(language: LanguageCode, timeResourceId: string): Promise<string> {
    const params = {
        asin: 'RINGTESTASIN',
        merchantId: STAGE[currentStage].merchantId,
        //https://katal.amazon.dev/for-developers/build-and-deploy/manifest-templating/
        storeId: timeResourceId,
        pageType: 'RING_SECURITY_EXPERTS',
        context: 'pc_optout_samay',
        clientId: 'RING_SECURITY_EXPERTS',
        marketplaceCode: 'US',
        presentationTimeZone: moment.tz.guess(),
        calendarImplementationType: 'KATAL',
    };
    const data = await fetch(`${STAGE[currentStage].calendarSourceId}?${new URLSearchParams(params)}`, {
        headers: {
            'Accept-Language': language,
        },
    });
    return await data.text();
}
