import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { updateFormField } from 'utils/form';

import { ChangePersonDetailsForm, PersonalDetailsFormFieldType, PersonDetailsFormType } from 'types/state';

export const counterSlice = createSlice<PersonDetailsFormType, SliceCaseReducers<PersonDetailsFormType>, string>({
    name: 'personDetailsForm',
    initialState: {
        firstName: {
            value: undefined,
            status: 'default',
            message: undefined,
        },
        lastName: {
            value: undefined,
            status: 'default',
            message: undefined,
        },
        phone: {
            value: undefined,
            status: 'default',
            message: undefined,
        },
    },
    reducers: {
        changePersonDetailsForm: (state, action: PayloadAction<ChangePersonDetailsForm>) => {
            return {
                ...state,
                [action.payload.field]: action.payload.data,
            };
        },
        validatePersonDetailsForm: (state) => {
            return {
                ...state,
                firstName: updateFormField(state.firstName.value, 'firstName'),
                lastName: updateFormField(state.lastName.value, 'lastName'),
                phone: updateFormField(state.phone.value, 'phone'),
            };
        },
        validatePersonDetailsField: (state, action: PayloadAction<PersonalDetailsFormFieldType>) => {
            return {
                ...state,
                [action.payload]: updateFormField(state[action.payload].value, action.payload),
            };
        },
    },
});

export const {
    changePersonDetailsForm,
    validatePersonDetailsForm,
    validatePersonDetailsField,
} = counterSlice.actions;
export const personDetailsFormReducer = counterSlice.reducer;
