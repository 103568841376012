import { Alpha2Code } from 'iso-3166-1-ts';

import { COUNTRIES } from 'constants/countries';
import { getUserAlpha2FromLang } from 'utils/country';

import { PhoneType } from 'types/common';

/**
 * This function removes any characters other than numbers from the domestic phone number
 * @param phone
 */
export function getDomesticPhoneNumber(phone: string): string {
    return phone.replace(/\D/g, '');
}

/**
 * This function returns the phone representation expected by the backend
 * @param phone
 */
export function formatPhoneNumberForBackend(phone: PhoneType): string {
    return `+${phone.code}${getDomesticPhoneNumber(phone.number)}`;
}

/**
 * This function joins phone code and domestic number
 * @param phone
 */
export function getFullPhoneNumber(phone: PhoneType) {
    return `+${phone.code} ${phone.number}`;
}

/**
 * This function applies a given mask to value, where 0 can be any digit.
 * @param mask
 * @param value
 */
export function applyPhoneMask(mask: string, value?: string): string | undefined {
    if (!value) {
        return undefined;
    }
    const number = value.replace(/\D/g,'').split('').reverse();
    if (number.length === 0) {
        return undefined;
    }
    let index = mask.length;

    const result = mask.replace(/0/g, (match, offset) => {
        const lastNumber = number.pop();
        if (number.length === 0 && lastNumber) {
            index = offset;
        }
        return lastNumber || '';
    });
    return result.slice(0, index + 1) + number.reverse().join('');
}

/**
 * This function format phone number using mask '{phoneCode} (000) 000 - 0000'
 * The function assumes that the passed phone number starts with a 'phoneCode'
 * Otherwise, it returns a given 'phoneNumber' without transformation
 * @param phoneNumber
 * @param phoneCode
 */
export function formatPhoneNumber(phoneNumber: string, phoneCode: string) {
    const code = phoneNumber.slice(0, phoneCode.length);
    const domesticPhoneNumber = applyPhoneMask('(000) 000 - 0000', phoneNumber.slice(phoneCode.length));
    if (phoneCode !== code || !domesticPhoneNumber) {
        return phoneNumber;
    }
    return `${phoneCode} ${domesticPhoneNumber}`;
}

/**
 * This function returns a phone code (e.g. 44 for UK) from the passed language code (defined in RFC 5646).
 * If an alpha2 is not included in the language code,
 * then it returns undefined
 *
 * @param alpha2Codes
 * @param lang
 */
export function getUserPhoneCodeFromLang<T extends Alpha2Code>(alpha2Codes: T[], lang: string) {
    const langCountryCode = getUserAlpha2FromLang(alpha2Codes, lang);
    const alpha2 = alpha2Codes.find(code => code === langCountryCode);
    if (!alpha2 || !(alpha2 in COUNTRIES)) {
        return undefined;
    }
    return COUNTRIES[alpha2]?.phoneCode;
}
