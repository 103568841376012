import { FC, FormEvent, HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Link, PhoneInput } from '@amzn/ring-safe-web';
import { useLanguage } from 'hooks/useLanguage';

import { SUPPORTED_COUNTRIES } from 'constants/common';
import { block } from 'utils/block';
import { getUserPhoneCodeFromLang } from 'utils/phone';
import { isMobile } from 'utils/view';

import { PhoneType } from 'types/common';
import { Field } from 'types/state';

import './NewDeviceOTPForm.scss';

const b = block('new-device-otp-form');

type Props = {
    onButtonClick: (phone: PhoneType) => void;
    id: string;
}

export const NewDeviceOTPForm: FC<Props & HTMLAttributes<HTMLFormElement>> = ({
    className = '',
    id,
    onButtonClick,
    ...rest
}) => {
    const { t } = useTranslation();
    const [phone, setPhone] = useState<Field<PhoneType>>({
        status: 'default',
    });
    const isPhoneCorrect = phone.status === 'default';
    const { language } = useLanguage();

    const onChange = (code: number, number = '' ) => {
        setPhone({ value: { code, number }, status: 'default' });
    };

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (phone.value && isPhoneCorrect) {
            onButtonClick(phone.value);
        }
    };
    const onButtonSubmit = () => {
        if (phone.value && isPhoneCorrect) {
            onButtonClick(phone.value);
        }
    };

    return (
        <form
            className={b().mix(className)}
            id={id}
            onSubmit={onSubmit}
            {...rest}
        >
            <h1 className={b('header')}>
                {t('new_device_otp_form_header')}
            </h1>
            <PhoneInput
                id={`${id}-phone`}
                label={t('new_device_otp_form_phone_label')}
                message={t(phone.message || 'form_phone_message')}
                disabled={false}
                value={phone.value?.number}
                required
                status={phone.status}
                onChangeCallback={onChange}
                className={b('input')}
                type='tel'
                name='phone'
                data-heap-redact-text={true}
                tabIndex={rest.tabIndex}
                isAbsolute={true}
                alpha2Codes={SUPPORTED_COUNTRIES}
                checkedPhoneCode={getUserPhoneCodeFromLang(SUPPORTED_COUNTRIES, language)}
                closeOnClickOutside={true}
                closeOnFocusOutside={!isMobile()}
            />
            <p className={b('agreement')}>
                {t('form_agreement')}
            </p>
            <p className={b('terms-of-service')}>
                {t('terms_of_service')}
                <Link
                    type='external'
                    link='https://ring.com/terms'
                    color='neutral'
                    size='xs'
                    className={b('agreement-link')}
                    id={`${id}-terms-of-service`}
                    tabIndex={rest.tabIndex}
                >
                    {t('terms_of_service_link')}
                </Link>
            </p>
            <Button
                color='primary'
                size='m'
                variant='filled'
                onClick={onButtonSubmit}
                className={b('button')}
                // it is needed to catch "return" key type
                type='submit'
                disabled={!isPhoneCorrect || phone.value === undefined }
                id={`${id}-submit-button`}
            >
                {t('new_device_otp_form_button')}
            </Button>
        </form>
    );
};
