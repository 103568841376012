import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotificationQueue } from '@amzn/ring-safe-web';
import { getAppointmentFromURL } from 'api/appointment';

import { NewDeviceOTPModal } from 'components/NewDeviceOTPModal';
import { DEFAULT_RETRY_COUNT } from 'constants/common';
import { HttpStatusCode } from 'constants/httpCodes';
import { getCookie } from 'utils/cookies';
import { getAppointmentId } from 'utils/url';

import { Cookies } from 'types/cookies';
import { NotificationEnum } from 'types/notification';


export const CalendarNewDeviceOTPModal = () => {
    const { t } = useTranslation();
    const [isNewDeviceOTPModalOpen, setIsNewDeviceOTPModalOpen] = useState(false);
    const notifications = useNotificationQueue();
    const receiveAppointment  = async () => {
        const id = getAppointmentId(location.search);
        if (!id) {
            return;
        }
        const appointment = await getAppointmentFromURL(DEFAULT_RETRY_COUNT);
        if (!appointment && !getCookie(Cookies.isAuthorized)) {
            setIsNewDeviceOTPModalOpen(true);
        }
        if (!appointment) {
            notifications.add({
                type: 'error',
                message: t('pages_appointment_notification_failed_receiving_of_appointments'),
                isClosed: true,
            }, NotificationEnum.FailedReceivingOfAppointments);
        }
    };

    const onSubmit = (status: HttpStatusCode) => {
        if (status === HttpStatusCode.OK) {
            setIsNewDeviceOTPModalOpen(false);
            notifications.removeAll();
        }
    };

    useEffect(() => {
        receiveAppointment();
    }, []);

    return (
        <NewDeviceOTPModal
            isOpen={isNewDeviceOTPModalOpen}
            onSubmitOTP={onSubmit}
        />
    );
};
