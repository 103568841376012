import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { TenantConfig } from 'types/state';
export const initialState = null;

export const tenantConfigSlice = createSlice<TenantConfig | null, SliceCaseReducers<TenantConfig | null>, string>({
    name: 'services',
    initialState,
    reducers: {
        setTenantConfig: (state, action: PayloadAction<TenantConfig>) => {
            return action.payload;
        },
    },
});

export const {
    setTenantConfig,
} = tenantConfigSlice.actions;
export const tenantConfigReducer = tenantConfigSlice.reducer;
