import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { challengeIdReducer } from 'pages/AccountVerificationPage/AccountVerificationPageSlice';
import { StoreEnhancer } from 'redux';

import { tenantConfigReducer } from 'components/App/AppSlice';
import { calendarReducer } from 'components/Calendar/CalendarSlice';
import { interestsReducer } from 'components/Interests/InterestsSlice';
import { personDetailsFormReducer } from 'components/PersonDetailsForm/PersonDetailsFormSlice';
import { smsVerificationFormReducer } from 'components/SMSVerificationForm/SMSVerificationFormSlice';

import { StateType } from 'types/state';

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

const reducer = {
    personDetailsForm: personDetailsFormReducer,
    interests: interestsReducer,
    smsVerificationForm: smsVerificationFormReducer,
    challengeId: challengeIdReducer,
    calendar: calendarReducer,
    tenantConfig: tenantConfigReducer,
};

export function getStore(preloadedState?: Partial<StateType>, enhancers?: StoreEnhancer[]) {
    return configureStore({
        reducer,
        preloadedState,
        enhancers,
    });
}

export const store = configureStore({
    reducer,
});

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
