import { HttpStatusCode } from 'constants/httpCodes';

import { InputStatus, Timeout } from 'types/common';
import { SMSVerificationFormNotification, SMSVerificationFormStatus } from 'types/state';

const RESET_BUTTON_LABEL = {
    [SMSVerificationFormStatus.CODE_ERROR]: 'sms_verification_form_sms_retry_timer',
    [SMSVerificationFormStatus.PHONE_400]: 'sms_verification_form_sms_default',
    [SMSVerificationFormStatus.PHONE_ERROR]: 'sms_verification_form_sms_default',
    [SMSVerificationFormStatus.PHONE_429]: 'sms_verification_form_sms_default',
    [SMSVerificationFormStatus.SUCCESS]: 'sms_verification_form_sms_default',
    [SMSVerificationFormStatus.PENDING]: 'sms_verification_form_sms_retry_timer',
    [SMSVerificationFormStatus.DEFAULT]: 'sms_verification_form_sms_default',
};

/**
 * This function returns a label for the form resend button
 * @param status
 * @param codeStatus
 * @param intervalId
 */
export function getResendButtonLabel(status: SMSVerificationFormStatus, codeStatus: InputStatus, intervalId?: Timeout): string {
    let label = RESET_BUTTON_LABEL[status];
    if ([SMSVerificationFormStatus.PENDING, SMSVerificationFormStatus.CODE_ERROR].includes(status) && intervalId === undefined) {
        if (codeStatus === 'error') {
            return 'sms_verification_form_sms_incorrect_retry';
        }
        return 'sms_verification_form_sms_retry';
    }
    return label;
}

const PHONE_MESSAGE_TITLE = {
    [HttpStatusCode.BAD_REQUEST]: 'sms_verification_form_phone_bad_request_title',
    [HttpStatusCode.TOO_MANY_REQUESTS]: 'sms_verification_form_too_many_requests_title',
    DEFAULT: 'sms_verification_form_phone_common_error_title',
};

const PHONE_MESSAGE_TITLE_DESCRIPTION: typeof PHONE_MESSAGE_TITLE = {
    [HttpStatusCode.BAD_REQUEST]: 'sms_verification_form_phone_bad_request_description',
    [HttpStatusCode.TOO_MANY_REQUESTS]: 'sms_verification_form_too_many_requests_description',
    DEFAULT: 'sms_verification_form_phone_common_error_description',
};

const CODE_MESSAGE_TITLE = {
    [HttpStatusCode.BAD_REQUEST]: 'sms_verification_form_phone_bad_request_title',
    [HttpStatusCode.UNAUTHORIZED]: 'sms_verification_form_code_expired_title',
    [HttpStatusCode.TOO_MANY_REQUESTS]: 'sms_verification_form_too_many_requests_title',
    DEFAULT: 'sms_verification_form_phone_common_error_title',
};

const CODE_MESSAGE_TITLE_DESCRIPTION: typeof CODE_MESSAGE_TITLE = {
    [HttpStatusCode.BAD_REQUEST]: 'sms_verification_form_phone_bad_request_description',
    [HttpStatusCode.UNAUTHORIZED]: 'sms_verification_form_code_expired_description',
    [HttpStatusCode.TOO_MANY_REQUESTS]: 'sms_verification_form_too_many_requests_description',
    DEFAULT: 'sms_verification_form_phone_common_error_description',
};

export function getPhoneNotificationMessage(code?: HttpStatusCode): SMSVerificationFormNotification {
    const key = (code && code in PHONE_MESSAGE_TITLE  ? code : 'DEFAULT') as keyof typeof PHONE_MESSAGE_TITLE;
    return {
        title: PHONE_MESSAGE_TITLE[key],
        description: PHONE_MESSAGE_TITLE_DESCRIPTION[key],
    };
}

export function getCodeNotificationMessage(code?: HttpStatusCode): SMSVerificationFormNotification {
    const key = (code && code in CODE_MESSAGE_TITLE  ? code : 'DEFAULT') as keyof typeof CODE_MESSAGE_TITLE;
    return {
        title: CODE_MESSAGE_TITLE[key],
        description: CODE_MESSAGE_TITLE_DESCRIPTION[key],
    };
}
